import React from 'react';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

import {parseDiff, Diff, Hunk, tokenize, markEdits} from 'react-diff-view';

import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago';

JavascriptTimeAgo.addLocale(en);

import Utils from '../stores/Utils';

class Changelog extends React.Component {
  static contextType = RouterContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      changelog: null
    };
  }

  async componentDidMount() {
    this.setState({loading: true});
    const changelog = await this.context.options.desktopStore.getChangelog();
    this.setState({changelog, loading: false});
  }

  render() {
    let content;
  
    if (this.state.loading) {
      content = ( 
        <span className="loading">
          <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#watch'}/></svg> Loading...
        </span>
      );
    }
  
    if (!this.state.loading && (!this.state.changelog || this.state.changelog.length < 1)) {
      content = (
        <h5>No activity yet.</h5>
      );
    }
  
    if (!this.state.loading && this.state.changelog && this.state.changelog.length > 0) {
      content = this.state.changelog.map(change => {
        let contentChange;
        
        if (change.action == 'update_content' && change.dataType == 'note') {
          const files = parseDiff(change.contentChange, {nearbySequences: 'zip'});

          if (files && files[0]) {
            const hunks = files[0].hunks;
          
            if (hunks) {
              const options = {
                highlight: false,
                enhancers: [markEdits(hunks, {type: 'block'})],
              };

              const tokens = tokenize(hunks, options);
        
              const renderFile = ({oldRevision, newRevision, type, hunks}) => (
                <Diff key={oldRevision + '-' + newRevision} gutterType="none" viewType="unified" diffType={type} hunks={hunks} tokens={tokens}>
                  {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
                </Diff>
              );

              contentChange = (
                <div className="diff-container">
                  {renderFile(files[0])}
                </div>
              );
            }
          }
        }
        
        const content = (
          <div key={change.uuid} className="row justify-content-center">
            <div className="col-12 m-sm-auto">
              <div className="row align-items-center">
                <div className="col-2 user-container">
                  {change.user && change.user != 'anonymous' && <>
                    { false && <img alt="image" className="img-fluid rounded-circle" src={`${railsAssetsUrl}/people/anonymous.jpg`} title="Anonymous" /> }
                    <svg className="feather avatar" title={change.user.name || change.user.email}><use xlinkHref={railsFeatherAssetsUrl + '#user'} /></svg>
                    <span className="user" title={change.user.name || change.user.email}>{change.user.name || change.user.email}</span>
                  </>}

                  {change.user == 'anonymous' && <>
                    <svg className="feather avatar" title="Anonymous"><use xlinkHref={railsFeatherAssetsUrl + '#user'}/></svg>
                    <span className="user" title="Anonymous">Anonymous</span>
                  </>}

                  {!change.user && <>
                    <svg className="feather avatar" title="Removed User"><use xlinkHref={railsFeatherAssetsUrl + '#user'}/></svg>
                    <span className="user" title="Removed User">Removed User</span>
                  </>}
                </div>

                <div className="col-10">
                  <h5 className="action">{Utils.changelogText(change)}</h5>
                  <p className="time"><ReactTimeAgo date={change.created} timeStyle="round" /></p>
      
                  { change.action == 'update_content' && change.dataType == 'note' &&
                  <>
                    { contentChange }
                  </> }
                </div>
              </div>
            </div>
          </div>
        );
        
        return content;
      });
    }
  
    return (
      <section className="fdb-block changelog">
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <h2>Activity</h2>
              <a className="close" aria-label="Close" onClick={(evt) => this.props.onClose(evt)}>
                <span aria-hidden="true">&times;</span>
              </a>
            </div>
          </div>
          
          {content}
        </div>
      </section>
    );      
  }
}

export default Changelog;