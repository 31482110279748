import {browserHistory, createRouterState, HistoryAdapter, RouterStore, RouterContext, RouterView} from 'mobx-state-router';
import actionCable from 'actioncable';

import WebsocketStore from '../stores/WebsocketStore';
import DesktopStore from '../stores/DesktopStore';
import UserStore from '../stores/UserStore';

import {routes, viewMap} from './routes';

import { configure } from 'mobx';

// configure({enforceActions: "never"});

const cableApp = {};
cableApp.cable = actionCable.createConsumer('/api/stream');

const notFound = createRouterState('notFound');

const initRouter = () => {
  const routerStore = new RouterStore(routes, notFound, {
    userStore: new UserStore(),
    desktopStore: new DesktopStore(),
    websocketStore: new WebsocketStore(cableApp)
  });

  routerStore.options.userStore.rootStore = routerStore;
  routerStore.options.desktopStore.rootStore = routerStore;
  routerStore.options.websocketStore.rootStore = routerStore;

  // Observe history changes
  const historyAdapter = new HistoryAdapter(routerStore, browserHistory);
  
  // Due to a bug, routes are now loaded twice on page load. Prevent it when some re-loads like accept invitation, may lead to error second time.
  if (!window.location.pathname || (window.location.pathname && !window.location.pathname.includes('/invitation/'))) {
    browserHistory.replace(window.location.pathname, {});
  }
  
  historyAdapter.observeRouterStateChanges();
  
  return routerStore;
};

export default initRouter;