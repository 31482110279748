import React from 'react';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

class ItemSettingsModal extends React.Component {
  static contextType = RouterContext;

  constructor(props) {
    super(props);
  
    this.state = {
      moveToFolderUuid: 'root',
      newTag: ''
    };
  }
  
  componentWillUnmount() {
    this.props.handleShowItemSettingsModal(false, false);
  }
  
  setMoveToFolderUuid(moveToFolderUuid) {
    this.setState({moveToFolderUuid});
  }

  async checkEditInput(evt) {
    if (evt && evt.key == 'Escape') {
      this.props.handleShowItemSettingsModal(false, false);
    }
  }
  
  pickColor(color) {
    this.props.desktopStore.updateItem({uuid: this.props.itemData.uuid, color: color});  
  }

  deleteItem(evt) {
    evt.preventDefault();
    this.props.desktopStore.deleteItem({uuid: this.props.itemData.uuid});
  }

  moveToFolder() {
    this.props.desktopStore.moveItem(this.props.desktopStore.data.uuid, this.props.itemData.uuid, this.state.moveToFolderUuid);
  }

  setNewTag(newTag) {
    this.setState({newTag});
  }
  
  addNewTag(evt) {
    if (!evt || (evt && evt.key == 'Enter')) {
      if (!this.props.itemData.tags.includes(this.state.newTag)) {
        this.props.toggleItemTag(this.props.desktopStore, this.props.itemData.uuid, this.state.newTag, true);
        this.setState({newTag: ''});
      }
    }
  }

  render() {
    const desktopStore = this.props.desktopStore;
    const itemData = this.props.itemData;
    const desktopData = this.props.desktopStore.data;
    const folders = desktopData.items.filter(item => item.generalType == 'folder' && item.uuid != itemData.uuid);
    
    let tagPicker;
  
    tagPicker = (
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label>Tags:</label>
      
            <div className="input-group">
              <input type="text" className="add-tag form-control" placeholder="Add new tag" value={this.state.newTag} onChange={evt => this.setNewTag(evt.target.value)} onKeyDown={evt => this.addNewTag(evt)} />
          
              <div className="input-group-append">
                <button type="button" className="btn btn-dark btn-sm" onClick={evt => this.addNewTag()}>Add tag</button>
              </div>
            </div>
            
            <div className="tags-container">
              <div className="edit-tags">
                { desktopStore.data.availableTags.map(tag => 
                  <button key={tag} type="button" className={'btn shadow-none ' + (itemData.tags.includes(tag) ? 'btn-dark toggled' : 'untoggled btn-outline-dark')} onClick={(evt) => this.props.toggleItemTag(desktopStore, itemData.uuid, tag, false)}>
                    {tag}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    
    let moveToFolder;
    
    if (folders.length) {
      moveToFolder = (
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="move-to-folder">Move to folder:</label>
              <select id="move-to-folder" className="form-control" value={this.state.moveToFolderUuid} onChange={(evt) => this.setMoveToFolderUuid(evt.target.value)} onMouseDown={(evt) => {evt.stopPropagation()}} onMouseUp={(evt) => {evt.stopPropagation()}}>
                <option value="root">- Top -</option>

                {folders.map(folder => {
                  return <option key={folder.uuid} value={folder.uuid}>{folder.title}</option>
                })}
              </select>
                
              <input type="button" className="btn btn-dark btn-sm mt-2" value="Move to folder" onClick={evt => this.moveToFolder()} />
            </div>
          </div>
        </div>
      );
    }

    let editItemData;
  
    if (itemData.dataType == 'url') {
      editItemData = <>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="url">URL:</label>
              <input type="text" autoFocus={true} className="form-control" id="url" placeholder="Enter URL" value={this.props.updatedItemData} onChange={this.props.handleSetItemData} onKeyDown={evt => this.checkEditInput(evt)} />
            </div>
          </div>
        </div>
      </>;
    }
    
    if (itemData.dataType == 'file' || itemData.image) {
      editItemData = <>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="ext">File Ext:</label>
              <input type="text" autoFocus={true} className="form-control" id="ext" placeholder="Enter File Extension" value={this.props.updatedItemData} onChange={this.props.handleSetItemData} onKeyDown={evt => this.checkEditInput(evt)} />
            </div>
          </div>
        </div>
      </>;
    }
    
    const colors = ['blue', 'green', 'yellow', 'red', 'purple'];
  
    const colorPicker = (
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label>Choose color:</label>
            <div className="color-picker">
              {colors.map(color => 
                <div key={`pick-color-${color}`} className={color + (itemData.color == color ? ' selected' : '')} onClick={() => this.pickColor(color)} />
              )}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{itemData.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(evt) => this.props.handleShowItemSettingsModal(false, true)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                {editItemData}
                {tagPicker}
                {colorPicker}
                {moveToFolder}
              </div>
            </div>

            <div className="container">
              <div className="col">
                {this.state.error &&
                <div className="alert alert-danger" role="alert">
                  {this.state.error}
                </div> }

                {this.state.success &&
                <div className="alert alert-success" role="success">
                  {this.state.success}
                </div> }
              </div>
            </div>

            <div className="modal-footer">
              <a href="#" onClick={(evt) => this.deleteItem(evt)}>Delete Item</a><br/>
              <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={(evt) => this.props.handleShowItemSettingsModal(false, true)}>Close</button>
            </div>
          </div>
        </div>
      </div>        
    );
  }
}

export default ItemSettingsModal;