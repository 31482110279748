class Utils {

  static changelogText(change) {
    let text = '';

    let dataType;
    
    if (change.action == 'create') {
      if (change.dataType == 'file') {
        text = `Uploaded ${change.dataType}`;
      } else {
        text = `Created ${change.dataType}`;
      }
      
      if (change.dataType == 'url') {
        text = text + ` ${change.labelA}`
      } else if (change.dataType == 'file') {
        text = text + ` "${change.itemTitle}.${change.labelA}"`
      }
    } else if (change.action == 'rename') {
      text = `Renamed ${change.dataType} "${change.itemTitle}" to "${change.labelA}"`;
    } else if (change.action == 'delete') {
      text = `Deleted ${change.dataType} "${change.itemTitle}"`;
    } else if (change.action == 'move_to_folder') {
      text = `Moved ${change.dataType} "${change.itemTitle}" to folder "${change.labelA}"`;
    } else if (change.action == 'update_content') {
      text = `Updated ${change.dataType} "${change.itemTitle}"`;
      
      if (change.dataType == 'url') {
        text = text + ` from ${change.labelA} to ${change.labelB}`;
      }
    }
      
    return text;
  }

}

export default Utils;