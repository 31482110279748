import React, { useState, useEffect, useRef, useCallback, memo, useContext } from 'react';
import {RouterLink} from 'mobx-state-router';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

import App from './App';

const Pricing = observer(
  class Pricing extends React.Component {
    static contextType = RouterContext;
  
    constructor(props) {
      super(props);
    
      this.state = {};
    }
  
    render() {
      return (
        <App>
        
          <section id="pricing" className="fdb-block">
            <div className="container">
              <div className="row text-center">
                <div className="col">
                  <h1>Pricing Plans</h1>
                </div>
              </div>
    
              <div className="row mt-5 align-items-center no-gutters">
                <div className="col-12 col-sm-10 col-md-8 m-auto col-lg-4 text-center shadow">
                  <div className="bg-white pb-5 pt-5 pl-4 pr-4 rounded-left">
                    <h2 className="font-weight-light">Free</h2>
    
                    <p className="h1 mt-5 mb-5"><strong>$0</strong> <span className="h4">/month</span></p>
    
                    <ul className="text-left">
                      <li>Max. 5MB file size</li>
                      <li>Max. 2 members per workspace</li>
                    </ul>
    
                    <p className="text-center pt-4">
                      {this.context.options.userStore.data.userUuid && 
                        <RouterLink routeName="createDesktop" className="btn btn-outline-dark">Create Workspace Now</RouterLink> }
                        
                      {!this.context.options.userStore.data.userUuid && 
                        <RouterLink routeName="signup" className="btn btn-outline-dark">Sign Up For Free</RouterLink> }
                    </p>
                  </div>
                </div>
    
                <div className="col-12 col-sm-10 col-md-8 ml-auto mr-auto col-lg-4 text-center mt-4 mt-lg-0 sl-1 pt-0 pt-lg-3 pb-0 pb-lg-3 bg-white fdb-touch rounded shadow">
                  <div className="pb-5 pt-5 pl-4 pr-4">
                    <h2 className="font-weight-light">Personal</h2>
    
                    <p className="h1 mt-5 mb-5"><strong>$1.99</strong> <span className="h4">/month</span></p>
    
                    <ul className="text-left">
                      <li>Max. 100MB file size</li>
                      <li>Max. 5 members per workspace</li>
                    </ul>
    
                    <p className="text-center pt-4">
                      <RouterLink routeName="requestPlan" params={{plan_type: 'personal'}} className="btn btn-dark btn-shadow">Choose Plan</RouterLink>
                    </p>
                  </div>
                </div>
    
                <div className="col-12 col-sm-10 col-md-8 ml-auto mr-auto col-lg-4 text-center mt-4 mt-lg-0 shadow">
                  <div className="bg-white pb-5 pt-5 pl-4 pr-4 rounded-right">
                    <h2 className="font-weight-light">Business</h2>
    
                    <p className="h1 mt-5 mb-5"><strong>$9.99</strong> <span className="h4">/month</span></p>
    
                    <ul className="text-left">
                      <li>Max. 1GB file size</li>
                      <li>Max. 10 members per workspace</li>
                    </ul>
    
                    <p className="text-center pt-4">
                      <RouterLink routeName="requestPlan" params={{plan_type: 'business'}} className="btn btn-dark btn-shadow">Choose Plan</RouterLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
                  
        </App>
      );
    }
  }
);

export default Pricing;