import React from 'react';
import { makeAutoObservable, makeObservable, observable, computed, action, autorun } from 'mobx';
import { createRouterState } from 'mobx-state-router';
import request from 'superagent';

class UserStore {
  data = {}
  checkedCurrentUser = false
  invitationUuid = null

  constructor() {
    this.init();
    makeAutoObservable(this);
    
    autorun(() => {
    });
  }

  init() {
    this.data = {
      userUuid: null,
    };
  }
  
  setInvitationUuid(invitationUuid) {
    this.invitationUuid = invitationUuid;
  }

  setLoggedInRedirect(loginRedirect) {
    this.loginRedirect = loginRedirect;
  }
  
  async logout() {
    analyticsTrack('logged-out');
    await request.delete('/users/sign_out').set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content);
  }
  
  async login(email, password) {
    try {
      this.data = (await request.post('/users/sign_in').send({email, password}).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      this.data.loginError = false;

      analyticsTrack('logged-in', {error: false});
      
      if (this.loginRedirect) {
        this.rootStore.goToState(this.loginRedirect);
      } else {
        this.rootStore.goToState(createRouterState('home'));
      }
    } catch (e) {
      analyticsTrack('logged-in', {error: true});
      
      if (e.response) {
        this.data.loginError = JSON.parse(e.response.text);
      } else {
        throw e;
      }
    }
  }
  
  async signup(name, email, password) {
    try {
      this.data = (await request.post('/users').send({name, email, password}).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      this.data.signupError = false;

      analyticsTrack('signed-up', {error: false});
      
      if (this.loginRedirect) {
        this.rootStore.goToState(this.loginRedirect);
      } else {
        this.rootStore.goToState(createRouterState('home'));
      }
    } catch (e) {
      analyticsTrack('signed-up', {error: true});
      
      if (e.response) {
        this.data.signupError = JSON.parse(e.response.text);
      } else {
        throw e;
      }
    }
  }
  
  async update(name, email, password, emailOncePer) {
    let error;
    
    try {
      this.data = (await request.patch('/users').send({name, email, password, emailOncePer}).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      analyticsTrack('updated-account');
    } catch (e) {
      error = e.response.text;
    }
    
    return error;
  }
  
  async resetPassword(email) {
    let error;
    
    try {
      await request.post('/api/reset_password').send({email}).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content);
      analyticsTrack('reset-password');
    } catch (e) {
      error = e.response.text;
    }
    
    return error;
  }
  
  async changePassword(token, password) {
    let error;
    
    try {
      await request.post('/api/change_password').send({token, password}).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content);
    } catch (e) {
      error = e.response.text;
    }
    
    return error;
  }
  
  async contact(email, message) {
    let error;
    
    try {
      await request.post('/api/contact').send({email, message}).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content);
    } catch (e) {
      error = e.response.text;
    }
    
    return error;
  }
  
  async delete() {
    try {
      this.data = (await request.delete('/users').set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      analyticsTrack('deleted-account');
      this.rootStore.goTo('home');
    } catch (e) {
      alert(e.response.text);
    }
  }
  
  async getCurrentUser() {
    try {
      this.data = (await request.get('/api/current_user').query({})).body;
      this.data.loginError = false;
      this.data.signupError = false;
    } catch (e) {
      this.data.loginError = e.response.text;
    }

    this.data.loading = false;
  }

}

export default UserStore;