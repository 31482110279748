import React, { useState, useEffect, useRef, useCallback, memo, useContext } from 'react';
import {RouterLink} from 'mobx-state-router';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

import App from './App';

const Login = observer(
  class Login extends React.Component {
    static contextType = RouterContext;
  
    constructor(props) {
      super(props);
    
      this.state = {
        email: '',
        password: '',
        error: null
      };
    }
  
    setEmail(email) {
      this.setState({email});
    }

    setPassword(password) {
      this.setState({password});
    }

    async login() {
      this.context.options.userStore.login(this.state.email, this.state.password);
    }

    render() {
      return (
        <App>
        
          <section className="fdb-block py-0">
            <div id="login-form" className="container py-5 my-5">
              <div className=" row justify-content-end">
                <div className="col-12 col-md-8 col-lg-6 col-xl-5 text-left">
                  <div className="fdb-box">
                    <div className="row">
                      <div className="col">
                        <h1>Log In</h1>
                        { false && <p className="lead">Right at the coast of the Semantics, a large language ocean. A small river named Duden.</p> }
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-4">
                        <input type="text" className="form-control" onChange={evt => this.setEmail(evt.target.value)} value={this.state.email} placeholder="Your email" autoFocus={true}/>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <input type="password" className="form-control" onChange={evt => this.setPassword(evt.target.value)} value={this.state.password} placeholder="Your password"/>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <button className="btn btn-dark btn-lg" type="button" onClick={()=>this.login()}>Login</button>
                        <p className="text-right">
                          No account yet? <RouterLink routeName="signup">Sign Up</RouterLink>
                        </p>
                        <p className="text-right">
                          Forgot your password? <RouterLink routeName="resetPassword">Reset Password</RouterLink>
                        </p>

                        {this.context.options.userStore.data.loginError && this.context.options.userStore.data.loginError.error &&
                          <div className="alert alert-danger mt-4" role="alert">
                            {this.context.options.userStore.data.loginError.error}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </App>
      );
    }
  }
);

export default Login;