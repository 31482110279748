import React, { useState, useEffect, useRef, useCallback, memo, useContext } from 'react';
import {RouterLink} from 'mobx-state-router';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

import App from './App';

const Signup = observer(
  class Signup extends React.Component {
    static contextType = RouterContext;
  
    constructor(props) {
      super(props);
    
      this.state = {
        name: '',
        email: '',
        password: ''
      };
    }
  
    setName(name) {
      this.setState({name});
    }

    setEmail(email) {
      this.setState({email});
    }

    setPassword(password) {
      this.setState({password});
    }

    checkEnter(evt) {
      if (evt && evt.key == 'Enter') {
        this.signup();
      }
    }

    async signup() {
      this.context.options.userStore.signup(this.state.name, this.state.email, this.state.password);
    }

    render() {
      return (
        <App>
        
          <section className="fdb-block py-0" style={{borderBottom: "1px solid var(--light)"}}>
            <div id="signup-form" className="container py-5 my-5">
              <div className="row">
                <div className="col-12 col-md-8 col-lg-7 col-xl-5 text-left">
                  <div className="row">
                    <div className="col">
                      <h1>Sign Up</h1>
                      <p className="lead">Sign up and access your workspaces any time you like.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col mt-4">
                      <input type="text" className="form-control" onChange={evt => this.setEmail(evt.target.value)} onKeyDown={(evt) => this.checkEnter(evt)} value={this.state.email} placeholder="Your email" autoFocus={true}/>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <input type="password" className="form-control" onChange={evt => this.setPassword(evt.target.value)} onKeyDown={(evt) => this.checkEnter(evt)} value={this.state.password} placeholder="Choose password"/>
                    </div>
                  </div>
        
                  <div className="row justify-content-start mt-4">
                    <div className="col">
                      <button onClick={()=>this.signup()} className="btn btn-dark btn-lg" type="button">Sign Up!</button>
        
                      {this.context.options.userStore.data.signupError && 
                        <div className="alert alert-danger mt-4" role="alert">
                          {this.context.options.userStore.data.signupError.error}
                        </div>
                      }
        
                      <div className="mt-4">
                        <label className="form-check-label">
                          <p>
                            By signing up you agree with our <a href="/privacy" target="_blank">Privacy Policy</a> and <a href="/terms" target="_blank">Terms of Use</a>
                          </p>
                        </label>
                      </div>
        
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>        
        </App>
      );
    }
  }
);

export default Signup;