import React from 'react';
import ReactDOM from 'react-dom';
import { makeAutoObservable, makeObservable, observable, computed, action, autorun } from 'mobx';
import request from 'superagent';
import Uploady from '@rpldy/uploady';

class DesktopStore {
  data = {}
  desktops = []
  selectedTags = []
  selectedColors = []
  tagsMatchType = 'any'
  showActivity = false
  
  uploadIds = {}
  uploadPreviewRef = React.createRef()
  showUploaderPreview = false
  scrollContainer = null
  currentDragItemData = null
  
  searchBy = false
  searchByText = ''
  sortBy = false
  searchLoading = false
  searchMode = false
  enablePaste = true
  anyModalOpen = false
  
  admin = false
  write = false

  constructor() {
    this.init();
    
    makeAutoObservable(this, {
      uploadIds: false,
      scrollContainer: false,
      uploadPreviewRef: false,
      getItemUuidForUploadId: false,
      withFilterOptions: false,
      currentDragItemData: false,
      setCurrentDragItemData: false
    });
    
    autorun(() => {
    });
  }

  init(options = {}) {
    this.selectedTags = [];
    this.selectedColors = [];
    this.desktops = [];
    
    if (options && !this.showActivity) {
      this.showActivity = options.showActivity;
    }
    
    this.searchBy = false;
    this.searchByText = '';
    this.sortBy = false;
    this.searchLoading = false;
    this.searchMode = false;
    this.enablePaste = true;
    this.anyModalOpen = false;
    
    this.admin = false;
    this.write = false;
    
    this.data = {
      loading: true,
      loadError: false,
      createError: false,
      editMode: false
    };
  }

  setShowUploaderPreview(showUploaderPreview) {
    this.showUploaderPreview = showUploaderPreview;
    
    if (!showUploaderPreview) {
      this.clearUploadPreviews();
    }
  }
  
  setScrollContainer(scrollContainer) {
    this.scrollContainer = scrollContainer;
  }
  
  clearUploadPreviews() {
    if (this.uploadPreviewRef && this.uploadPreviewRef.current) {
      this.uploadPreviewRef.current.clear();
    }
  }
  
  isItemInViewport(itemData) {
    if (this.scrollContainer && this.scrollContainer.current) {
      const containerEl = this.scrollContainer.current.container.current;

      const withinVerticalView = (itemData.x >= containerEl.scrollLeft);// && ((itemData.x + itemData.width) <= (containerEl.offsetWidth + containerEl.scrollLeft));
      const withinHorizontalView = (itemData.y >= containerEl.scrollTop);// && ((itemData.y + itemData.height) <= (containerEl.offsetHeight + containerEl.scrollTop));

      return withinVerticalView && withinHorizontalView;
    }
  }

  scrollContainerTo(x, y) {
    if (this.scrollContainer && this.scrollContainer.current) {
      ReactDOM.findDOMNode(this.scrollContainer.current).scrollTo(x, y);
    }
  }
  
  setCurrentDragItemData(itemData) {
    this.currentDragItemData = itemData;
  }
  
  setEditMode(mode) {
    this.data.editMode = mode;
  }
  
  setPaste(enable) {
    this.enablePaste = enable;
  }
  
  setShowActivity(show) {
    this.showActivity = show;
  }
  
  checkSearchMode() {
    this.searchLoading = true;
    
    if (this.selectedTags.length == 0 && this.selectedColors.length == 0 && !this.sortBy && !this.searchBy) {
      this.searchMode = false;
    } else {
      this.searchMode = true;
    }
  }
  
  resetSearch(evt) {
    if (evt) {
      evt.preventDefault();
    }
    
    this.clearToggledTags();
    this.clearToggledColors();
    this.setSortBy(false);
    this.setSearchByText('');
    this.setSearchBy(false);
    
    this.loadDesktop();
  }
  
  setAnyModalOpen(open) {
    this.anyModalOpen = open;
  }
  
  get editMode() {
    return this.data.editMode;
  }
  
  setSortBy(sortBy) {
    this.sortBy = sortBy;
    this.checkSearchMode();
  }
  
  setSearchBy(searchBy) {
    this.searchBy = searchBy;
    this.checkSearchMode();
  }
  
  setSearchByText(searchByText) {
    this.searchByText = searchByText;
  }
  
  clearToggledTags() {
    this.selectedTags = [];
    this.checkSearchMode();
  }
  
  toggleTag(selectedTag) {
    if (this.selectedTags.includes(selectedTag)) {
      this.selectedTags = this.selectedTags.filter(tag => tag != selectedTag);
    } else {
      this.selectedTags.push(selectedTag);
    }
    
    this.checkSearchMode();
  }
  
  isTagToggled(tag) {
    return this.selectedTags.includes(tag);
  }
  
  clearToggledColors() {
    this.selectedColors = [];
    this.checkSearchMode();
  }
  
  toggleColor(selectedColor) {
    if (this.selectedColors.includes(selectedColor)) {
      this.selectedColors = this.selectedColors.filter(tag => tag != selectedColor);
    } else {
      this.selectedColors.push(selectedColor);
    }
    
    this.checkSearchMode();
  }
  
  isColorToggled(color) {
    return this.selectedColors.includes(color);
  }
  
  setData(data) {
    this.data = data;
  }
  
  async getSignedUploadUrl(options) {
    const result = (await request.post('/api/sign_file_upload').send(options).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
    this.uploadIds[options.uploadId] = result.item_uuid;
    
    return result;
  }
  
  getItemUuidForUploadId(uploadId) {
    return this.uploadIds[uploadId];
  }
  
  withFilterOptions(data) {
    const tagsOptions = {tags: this.selectedTags, tagsMatchType: this.tagsMatchType, colors: this.selectedColors, sortBy: this.sortBy, searchBy: this.searchBy ? this.searchByText : null};
    return Object.assign({}, tagsOptions, data);
  }
  
  async loadDesktop(id, options = {}) {
    try {
      const result = (await request.post('/api/get_desktop').send(this.withFilterOptions({id: id || this.data.uuid, options: options})).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      this.data = result.desktop;
      
      this.admin = result.role.admin;
      this.write = result.role.write;
      
      this.data.loadError = false;
    } catch (e) {
      this.data.loadError = JSON.parse(e.response.text);
    }

    this.searchLoading = false;
    this.data.loading = false;
  }
  
  async createDesktop(desktopData) {
    try {
      const newDesktop = (await request.post('/api/desktop').send(desktopData).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      analyticsTrack('desktop-created');

      this.admin = true;
      this.write = true;

      this.rootStore.goTo('desktop', {params: {id: newDesktop.shortcode}});
    } catch (e) {
      this.data.createError = e.response.text;
    }
  }

  async acceptInvite(invitationUuid) {
    let error;
    
    try {
      analyticsTrack('invite-accepted');
      
      return (await request.post('/api/accept_invite').send({invitationUuid}).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
    } catch (e) {
      error = JSON.parse(e.response.text);
    }
    
    return error;
  }
  
  async invite(emails, role) {
    let error;
    
    try {
      await request.post('/api/invite_members').send({emails, role, desktopUuid: this.data.uuid}).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content);
      
      analyticsTrack('invite-sent');
    } catch (e) {
      error = e.response.text;
    }
    
    return error;
  }
  
  async deleteMember(memberId) {
    let error;
    
    try {
      const data = (await request.post('/api/delete_member').send(this.withFilterOptions({desktopUuid: this.data.uuid, memberId})).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      this.data = data;
    } catch (e) {
      error = e.response.text;
    }
    
    return error;
  }
  
  async updateDesktop(desktopData) {
    let error;
    
    try {
      const data = (await request.put('/api/desktop').send(this.withFilterOptions({desktopUuid: this.data.uuid, ...desktopData})).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      this.data = data;
    } catch (e) {
      error = e.response.text;
    }
    
    this.getDesktops();

    return error;
  }
  
  async addTag(tag) {
    let error;
    
    try {
      const data = (await request.post('/api/add_tag').send(this.withFilterOptions({desktopUuid: this.data.uuid, tag: tag})).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      this.data = data;
    } catch (e) {
      error = e.response.text;
    }
    
    return error;
  }
  
  async deleteTag(tag) {
    let error;
    
    try {
      const data = (await request.delete('/api/delete_tag').send(this.withFilterOptions({desktopUuid: this.data.uuid, tag: tag})).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      this.data = data;
      
      if (this.isTagToggled(tag)) {
        this.toggleTag(tag);
        this.loadDesktop();
      }
    } catch (e) {
      error = e.response.text;
    }
    
    return error;
  }
  
  async deleteDesktop() {
    try {
      await request.delete('/api/desktop').send({desktopUuid: this.data.uuid}).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content);
      
      analyticsTrack('delete-desktop');
    } catch (e) {
      throw e;
    }

    this.getDesktops();
  }
  
  async getDesktops() {
    try {
      this.desktops = (await request.get('/api/desktops')).body;
    } catch (e) {
      throw e;
    }
    
    return this.desktops;
  }
  
  getTileOccupancyAreasMatrix(items, columnsCount, maxRows, itemWidth, itemHeight, containerWidth, containerHeight) {
    const tilesGroupedByRowColumn = {};

    items.forEach(item => {
      const xIdx = Math.floor(item.x / itemWidth);
      const yIdx = Math.floor(item.y / itemHeight);
    
      if (!tilesGroupedByRowColumn[yIdx]) {
        tilesGroupedByRowColumn[yIdx] = {};
      }
    
      if (!tilesGroupedByRowColumn[yIdx][xIdx]) {
        tilesGroupedByRowColumn[yIdx][xIdx] = {};
      }
    
      tilesGroupedByRowColumn[yIdx][xIdx] = {width: Math.ceil(item.width / itemWidth), height: Math.ceil(item.height / itemHeight)};
    });
    
    let tileOccupancyAreasMatrix = {};

    for (let row = 0; row < maxRows; row++) {
        for (let column = 0; column < columnsCount; column++) {
            if (typeof tileOccupancyAreasMatrix[row] === 'undefined') {
                tileOccupancyAreasMatrix[row] = {};
            }

            if (tileOccupancyAreasMatrix[row][column] === 1) {
                // columns have been marked used by some previous iteration,
                // that was handling tiles extending to more than 1 column width
                continue;
            }

            if (typeof tilesGroupedByRowColumn[row] === 'undefined'
                || typeof tilesGroupedByRowColumn[row][column] === 'undefined') {
                tileOccupancyAreasMatrix[row][column] = 0;
                continue;
            }

            let tileWidth = tilesGroupedByRowColumn[row][column].width;
            let tileHeight = tilesGroupedByRowColumn[row][column].height;
            // code below also handles the case when tile extends to next rows and column(s)
            for (let rowNumber = row; rowNumber < row + tileHeight; rowNumber++) {
                if (typeof tileOccupancyAreasMatrix[rowNumber] === 'undefined') {
                    tileOccupancyAreasMatrix[rowNumber] = {};
                }
                for (let columnNumber = column; columnNumber < column + tileWidth; columnNumber++) {
                    tileOccupancyAreasMatrix[rowNumber][columnNumber] = 1;
                }
            }
        }
    }

    return tileOccupancyAreasMatrix;
  }

  addItem(event, itemData, generalType, dataType, onAdd) {
    if (event) {
      event.preventDefault();
    }

    if (dataType == 'url' && !itemData.data) {
      alert('No URL entered');
    } else {
      this.createItem(this.data.uuid, {
        ...itemData,
        generalType: generalType,
        dataType: dataType,
        itemUuid: itemData.itemUuid,
        parentUuid: this.rootStore.routerState.params.parentId
      });
    
      if (onAdd) {
        onAdd();
      }
    }
  }
  
  async createItem(desktopUuid, itemData) {
    try {
      // Best to keep fixed for all items, which is biggest possible (e.g. note). Otherwise dir items may be placed under existing bigger notes.
      const itemWidth = 145 + 25;
      const itemHeight = 140 + 50;
      
      const columnsCount = Math.floor(window.innerWidth / itemWidth);
      const rowsCount = Math.floor(window.innerHeight / itemHeight);
      const occupancy = this.getTileOccupancyAreasMatrix(this.data.items, columnsCount, rowsCount, itemWidth, itemHeight, window.innerWidth, window.innerHeight);

      let useXIdx;
      let useYIdx;

      loop1:
      for (let y = 0; y < rowsCount; ++y) {
        loop2:
        for (let x = 0; x < columnsCount; ++x) {
          if (typeof(occupancy[y]) !== 'undefined' && typeof(occupancy[y][x]) !== 'undefined' && occupancy[y][x] == 0) {
            useXIdx = x;
            useYIdx = y;

            break loop1;
          }
        }
      }

      if (useXIdx >= 0 && useYIdx >= 0) {
        itemData.x = 25 + (itemWidth * useXIdx);
        itemData.y = 25 + (itemHeight * useYIdx);
      } else {
        // itemData.x = Math.round(document.getElementById('container-container').scrollLeft + (window.innerWidth / 2)) + Math.round((Math.random() * (Math.random() < 0.5 ? -1 : 1) * 75));
        // itemData.y = Math.round(document.getElementById('container-container').scrollTop + (window.innerHeight / 3)) + Math.round((Math.random() * (Math.random() < 0.5 ? -1 : 1) * 75));
        itemData.x = 50 + Math.round((Math.random() * (Math.random() < 0.5 ? -1 : 1) * 75));
        itemData.y = 75 + Math.round((Math.random() * (Math.random() < 0.5 ? -1 : 1) * 75));
      }
      
      const data = (await request.post('/api/item').send(this.withFilterOptions({desktopUuid: desktopUuid, ...itemData})).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      this.data = data;
      
      // Scroll to new item position, if not in viewport
      if (!this.isItemInViewport(itemData)) {
        this.scrollContainerTo(itemData.x - 150, itemData.y - 150);
      }
      
      analyticsTrack('create-item', {generalType: itemData.generalType, dataType: itemData.dataType});
    } catch (e) {
      throw e;
    }
  }
  
  async updateItem(itemData) {
    try {
      const data = (await request.put('/api/item').send(this.withFilterOptions(itemData)).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      this.data = data;
      
      analyticsTrack('update-item');
    } catch (e) {
    }
  }
    
  async moveItem(desktopUuid, fromItemUuid, toItemUuid) {
    try {
      if (fromItemUuid != toItemUuid) {
        const data = (await request.post('/api/item/move').send(this.withFilterOptions({desktopUuid, fromItemUuid, toItemUuid})).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
        this.data = data;
      }
    } catch (e) {
    }
  }
  
  async deleteItem(itemData) {
    try {
      const data = (await request.delete('/api/item').send(this.withFilterOptions(itemData)).set('X-CSRF-TOKEN', document.querySelector('[name=csrf-token]').content)).body;
      this.data = data;
      
      analyticsTrack('delete-item');
    } catch (e) {
    }
  }

  async getChangelog(itemUuid) {
    try {
      const data = (await request.get('/api/changelog').query({desktopUuid: this.data.uuid, itemUuid})).body;
      
      if (itemUuid) {
        analyticsTrack('view-desktop-changelog');
      } else {
        analyticsTrack('view-item-changelog');
      }

      return data;
      
    } catch (e) {
    }
  }
}

export default DesktopStore;