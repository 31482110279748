import React, { useState, useEffect, useRef, useCallback, memo, useContext } from 'react';
import {RouterLink} from 'mobx-state-router';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

import App from './App';

const Contact = observer(
  class Contact extends React.Component {
    static contextType = RouterContext;
  
    constructor(props) {
      super(props);
    
      this.state = {
        email: '',
        message: ''
      };
    }
  
    setEmail(email) {
      this.setState({email});
    }

    setMessage(message) {
      this.setState({message});
    }

    async contact(evt) {
      if (evt) {
        evt.preventDefault();
      }

      this.setState({error: null, success: null});
      const error = await this.context.options.userStore.contact(this.state.email, this.state.message);

      if (!error) {
        this.setState({success: 'Successfully sent message.'});
      } else {
        this.setState({error});
      }
    }

    render() {
      return (
        <App>
        
          <section className="fdb-block py-0">
            <div id="contact-form" className="container py-5 my-5">
              <div className="row py-5">
                <div className="col py-5">
                  <div className="fdb-box fdb-touch">
                    <div className="row text-center justify-content-center">
                      <div className="col-12 col-md-9 col-lg-7">
                        <h1>Contact Us</h1>
                        <p className="lead">Get ideas, found a problem or something else? Contact us!</p>
                      </div>
                    </div>
    
                    <div className="row justify-content-center pt-4">
                      <div className="col-12 col-md-8">
                        <form onSubmit={(evt) => this.contact(evt)}>
                          <div className="row">
                            <div className="col-12 col-md">
                              <input type="text" className="form-control" onChange={evt => this.setEmail(evt.target.value)} value={this.state.email} placeholder="Your email" autoFocus={true}/>
                            </div>
                          </div>
    
                          <div className="row mt-4">
                            <div className="col">
                              <textarea className="form-control" placeholder="How can we help?" rows={3} value={this.state.message} onChange={(evt) => this.setMessage(evt.target.value)} />
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col text-center">
                              <button type="button" className="btn btn-dark" onClick={() => this.contact()}>Contact</button>

                              {this.state.error &&
                              <div className="alert alert-danger mt-4" role="alert">
                                {this.state.error}
                              </div> }

                              {this.state.success &&
                              <div className="alert alert-success mt-4" role="success">
                                {this.state.success}
                              </div> }
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </App>
      );
    }
  }
);

export default Contact;