import React, { useState, useEffect, useRef, useCallback, memo, useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import ReactMarkdown from 'react-markdown';
import Uploady, {UploadyContext} from '@rpldy/uploady';
import gfm from 'remark-gfm';
import { observer } from 'mobx-react';
import { RouterContext, RouterLink } from 'mobx-state-router';
import { DndProvider, useDragLayer } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { getEmptyImage } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useDrop } from 'react-dnd';
import { useDrag } from 'react-dnd';
import { useDrop as useDropAndPaste } from 'react-use';
import { Resizable, ResizableBox } from 'react-resizable';
import update from 'immutability-helper';
import ScrollContainer from 'react-indiana-drag-scroll';
// import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

import App from './App';
import MembersModal from './MembersModal';
import DesktopSettingsModal from './DesktopSettingsModal';
import ItemSettingsModal from './ItemSettingsModal';
import Changelog from './Changelog';

import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago';

JavascriptTimeAgo.addLocale(en);

const customItemDragLayerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

const snapToGrid = (x, y) => {
  const snappedX = Math.round(x / 10) * 10;
  const snappedY = Math.round(y / 10) * 10;
  
  return [snappedX, snappedY]
};

const CustomItemDragLayer = (props) => {
  const { itemType, isDragging, item, initialOffset, currentOffset, } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const getItemStyles = (initialOffset, currentOffset) => {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none'
      };
    }
    
    let { x, y } = currentOffset;
    
    const isSnapToGrid = false;
    
    if (isSnapToGrid) {
      x -= initialOffset.x;
      y -= initialOffset.y;
      [x, y] = snapToGrid(x, y);
      x += initialOffset.x;
      y += initialOffset.y;
    }
        
    const transform = `translate(${x}px, ${y}px)`;
    
    return {
      transform,
      WebkitTransform: transform,
    };
  };
    
  if (!isDragging) {
    return null;
  }

  const renderItem = () => {
    const itemData = props.desktopStore.currentDragItemData;
  
    if (!itemData) {
      return null;
    }
  
    const icon = getItemIcon(itemData);
    const thinness = getItemThinness(itemData);

    return (
      <div className={`item-container ${icon} ${itemData.color} ${thinness}`} style={{}}>
        <div className="full-size">
          <ItemContent desktopStore={props.desktopStore} itemData={itemData} editMode={false} isDragging={true} />
        </div>
      </div>
    );
  };

  return (
    <div style={customItemDragLayerStyles}>
  		<div style={getItemStyles(initialOffset, currentOffset)}>
  			{renderItem()}
  		</div>
    </div>
  );
};

const getCustomItemDragStyles = (isDragging) => {
  return {
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : ''
  };
};

const ItemTitle = (props) => {
  let timer = 0;
  let delay = 200;
  let prevent = false;

  const [editMode, setEditMode] = useState(false);
  const [newTitle, setNewTitle] = useState(props.itemData.title);
  
  React.useEffect(() => {
    setNewTitle(props.itemData.title);
  }, [props.itemData.title]);
  
  const save = async (evt) => {
    if (!evt || (evt && evt.key == 'Enter')) {
      await props.desktopStore.updateItem({uuid: props.itemData.uuid, title: newTitle});
      setEditMode(false);
      props.desktopStore.setAnyModalOpen(false);
    } else if (evt && evt.key == 'Escape') {
      setEditMode(false);
      props.desktopStore.setAnyModalOpen(false);
    }
  }
  
  const doClickAction = () => {
  };

  const clickOutsideRef = useOnclickOutside(async () => {
    if (editMode) {
      await props.desktopStore.updateItem({uuid: props.itemData.uuid, title: newTitle});
      setEditMode(false);
      props.desktopStore.setAnyModalOpen(false);
    }
  });  

  const doDoubleClickAction = (evt) => {
    evt.stopPropagation(); // Prevent item container from triggering double click action (like opening a folder, etc).
    props.desktopStore.setAnyModalOpen(true);
    setEditMode(true);
  };

  const handleClick = () => {
    timer = setTimeout(() => {
      if (!prevent) {
        doClickAction();
      }
    
      prevent = false;
    }, delay);
  };
  
  const handleDoubleClick = (evt) => {
    clearTimeout(timer);
    prevent = true;
    doDoubleClickAction(evt);
  }

  if (editMode && props.desktopStore.write) {
    return (
      <input className="title-edit" ref={clickOutsideRef} autoFocus={true} value={newTitle} onChange={evt => setNewTitle(evt.target.value)} onKeyDown={evt => save(evt)} />
    );
  } else {
    const titleEl = <h1 title={props.itemData.title} className={"title" + (props.desktopStore.write ? " editable" : "")} onClick={() => handleClick()} onDoubleClick={(evt) => handleDoubleClick(evt)}>{props.itemData.title}</h1>;
    
    if (!props.desktopStore.searchMode) {
      return titleEl;
    } else {
      let byMember;
      
      if (props.itemData.members.length) {
        // For now only 1 member per item is displayed
        const member = props.itemData.members[0];
        
        if (member) {
          const title = `by ${member.displayName}`;
          byMember = <div className="created-by" title={title}>{title}</div>;
        }
      }
      
      return <>
        {titleEl}
        <div className="created-at">Added <ReactTimeAgo date={props.itemData.created} timeStyle="round" />{byMember}</div>
        {byMember}
      </>;
    }
  }
};

const ItemContent = (props) => {
  let itemContent;

  const checkDiscard = (evt) => {
    if (evt && evt.key == 'Escape') {
      props.discardEdit();
    }
  }
  
  if (props.itemData.dataType == 'note') {
    if (!props.editMode) {
      itemContent = <>
        <div className="content">
          <div className="content-box">
            <ReactMarkdown plugins={[gfm]} children={props.itemData.data} />
          </div>
        </div>
        <ItemTitle desktopStore={props.desktopStore} itemData={props.itemData} />
      </>
    } else {
      
      itemContent = <>
        <textarea autoFocus={true} value={props.updatedItemData} onChange={(evt) => {if (props.desktopStore.write){ props.handleSetItemData(evt); } }} onKeyDown={evt => checkDiscard(evt)} ref={props.clickOutsideRef} />
        <ItemTitle desktopStore={props.desktopStore} itemData={props.itemData} />
      </>
    }
  } else if (props.itemData.dataType == 'file') {
    const typeFontSize = props.typeFontSize || Math.round(props.itemData.width / 8);
    const sizeFontSize = props.sizeFontSize || Math.round(props.itemData.width / 10);
    
    itemContent = <>
      <div className="friendly-type" style={{fontSize: `${typeFontSize}px`}}>{props.itemData.friendlyFileType}</div>
      <div className="friendly-size" style={{fontSize: `${sizeFontSize}px`}}>{props.itemData.friendlyFileSize}</div>
      <ItemTitle desktopStore={props.desktopStore} itemData={props.itemData} />
    </>;
  } else {
    itemContent = <ItemTitle desktopStore={props.desktopStore} itemData={props.itemData} />;
  }
  
  if (!props.isDragging) {
    let minWidth;
    let minHeight;
    
    if (props.itemData.dataType == 'folder') {
      minWidth = 50
      minHeight = 42
    } else if (props.itemData.image) {
      minWidth = 50
      minHeight = 43
    } else if (props.itemData.dataType == 'note') {
      minWidth = 75
      minHeight = 72
    } else if (props.itemData.dataType == 'file') {
      minWidth = 50
      minHeight = 67
    } else if (props.itemData.dataType == 'url') {
      minWidth = 30
      minHeight = 30
    }
    
    itemContent = (
      <ResizableBox className={props.desktopStore.write && !props.desktopStore.searchMode && (props.isHover || props.isResizing) ? '' : 'hide-handle'} width={props.itemData.width} height={props.itemData.height} lockAspectRatio={true} draggableOpts={{}} minConstraints={[minWidth, minHeight]} onResizeStart={props.desktopStore.write && !props.desktopStore.searchMode ? props.resizeStart : null} onResize={props.desktopStore.write && !props.desktopStore.searchMode ? props.resizing : null} onResizeStop={props.desktopStore.write && !props.desktopStore.searchMode ? props.resizeStop : null}>
        {itemContent}
      </ResizableBox>
    );
  } else {
    itemContent = (
      <div style={{width: props.itemData.width, height: props.itemData.height}}>
        {itemContent}
      </div>
    );
  }
  
  return itemContent;
};

const handleItemDownload = (desktopStore, itemData) => {
  if (itemData.dataType == 'file') {
    const win = window.open(itemData.downloadUrl, '_blank');
    
    if (win) {
      win.focus();
    }
  } else if (itemData.dataType == 'folder') {
    const folderFiles = desktopStore.data.items.filter(findItem => findItem.dataType == 'file' && findItem.parentUuid == itemData.uuid);
      
    folderFiles.forEach(fileItem => {
      const win = window.open(fileItem.downloadUrl, '_blank');
      
      if (win) {
        win.focus();
      }
    });
  }
};

const Item = ({ itemData, hideSourceOnDrag, desktopStore, resizable }) => {
  const [updatedItemData, setUpdatedItemData] = useState(itemData.data);
  const [editMode, setEditMode] = useState(false);
  const [itemHovered, setItemHovered] = useState(false);
  const [showItemSettingsModal, setShowItemSettingsModal] = useState(false);

  React.useEffect(() => {
    setUpdatedItemData(itemData.data);
  }, [itemData.data]);
  
  const resetItemSettingsModal = () => {
    setUpdatedItemData(itemData.data);
  };

  const [{ isDragging }, drag, preview] = useDrag({
    item: { id: itemData.uuid, left: itemData.left, top: itemData.top, type: itemData.generalType },
    canDrag: (dragProps) => {
      return desktopStore.write && !desktopStore.anyModalOpen && !desktopStore.searchMode && !editMode;
    },
    begin: (monitoring) => {
      desktopStore.setCurrentDragItemData(itemData);
    },
    end: (item, monitor) => {
      desktopStore.setCurrentDragItemData(null);

      const dropResult = monitor.getDropResult();

      if (item && dropResult && dropResult.generalType == 'folder') {
        desktopStore.moveItem(desktopStore.data.uuid, item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  });
  
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  
  let drop, canDrop, isOver;

  // Who can be dropped on?
  [{ canDrop, isOver }, drop] = useDrop({
    accept: itemData.generalType == 'folder' ? ['item', 'folder', 'tag', 'color'] : ['tag', 'color'],
    drop: () => ({ id: itemData.uuid, generalType: itemData.generalType }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;

  let timer = 0;
  let delay = 200;
  let prevent = false;

  const doClickAction = () => {
  };
  
  const clickOutsideRef = useOnclickOutside(() => {
    if (editMode) {
      setEditMode(false);
      desktopStore.setEditMode(false);
      setItemHovered(false);
      
      if (updatedItemData != itemData.data && desktopStore.write) {
        desktopStore.updateItem({uuid: itemData.uuid, data: updatedItemData, allowEmptyData: itemData.dataType == 'note'});
      }
    }
  });  
  
  const doDoubleClickAction = (evt) => {
    evt.stopPropagation(); // Prevent item container from triggering double click action (like renaming title, etc).
    setItemHovered(false);

    if (!desktopStore.anyModalOpen) {
      if (itemData.generalType == 'folder') {
        desktopStore.resetSearch();
        desktopStore.rootStore.goTo('desktopWithParent', {params: {id: desktopStore.rootStore.routerState.params.id, parentId: itemData.uuid}});
      } else {
        if (itemData.dataType == 'url') {
          const win = window.open(itemData.data, '_blank');
          
          if (win) {
            win.focus();
          }
        } else if (itemData.dataType == 'note') {
          if (desktopStore.searchMode) {
            desktopStore.resetSearch();
            
            let foundContainer = setInterval(() => {
             if (desktopStore.scrollContainer && desktopStore.scrollContainer.current) {
                clearInterval(foundContainer);
                desktopStore.scrollContainerTo(itemData.x - 50, itemData.y - 50);
             }
            }, 200);
          } else {
            setEditMode(true);
            desktopStore.setEditMode(true);
          }
        } else if (itemData.dataType == 'file') {
          const win = window.open(itemData.downloadUrl, '_blank');
          
          if (win) {
            win.focus();
          }
        }
      }
    }
  };
  
  const handleClick = () => {
    timer = setTimeout(() => {
      if (!prevent) {
        doClickAction();
      }
      
      prevent = false;
    }, delay);
  };
    
  const handleDoubleClick = (evt) => {
    clearTimeout(timer);
    prevent = true;
    doDoubleClickAction(evt);
  }
  
  const handleSetItemData = (event) => {
    setUpdatedItemData(event.target.value);
  };
  
  const [isResizing, setIsResizing] = useState(false);
  const [thinness, setThinness] = useState(getItemThinness(itemData));
  const [typeFontSize, setTypeFontSize] = useState();
  const [sizeFontSize, setSizeFontSize] = useState();

  const resizeStart = (ev, data) => {
    ev.stopPropagation();
    ev.preventDefault();
    setIsResizing(true);
  };
  
  const resizing = (ev, data) => {
    const thinness = getItemThinness(itemData, data.size.width, data.size.height);
    setThinness(thinness);
    
    if (itemData.dataType == 'file') {
      setTypeFontSize(Math.round(data.size.width / 8));
      setSizeFontSize(Math.round(data.size.width / 10));
    }
  };
  
  const resizeStop = (ev, data) => {
    setIsResizing(false);
    desktopStore.updateItem({uuid: itemData.uuid, width: data.size.width, height: data.size.height});
  };
  
  const discardEdit = () => {
    setEditMode(false);
  };
  
  const handleShowItemSettingsModal = (show, saveOnHide) => {
    // Only for 'url', 'image' or 'file', we want to auto save 'data' if modal is closed.
    if ((itemData.dataType == 'url' || itemData.dataType == 'file' || itemData.image) && saveOnHide) {
      if (updatedItemData != itemData.data && desktopStore.write) {
        desktopStore.updateItem({uuid: itemData.uuid, data: updatedItemData});
      }
    }
    
    desktopStore.setAnyModalOpen(show);
    
    resetItemSettingsModal();
    setShowItemSettingsModal(show);
    setItemHovered(false);
  };
  
  let itemSettingsToggle;
  
  if (itemHovered && desktopStore.write) {
    itemSettingsToggle = (
      <div className="item-settings-toggle" onClick={(evt) => handleShowItemSettingsModal(true)}>
        <img src={`${railsAssetsUrl}/settings.svg`}/>
      </div>
    );
  }
  
  let itemDownloadHover;
  let hasDownload;
  
  if (itemData.dataType == 'file') {
    hasDownload = true;
  } else if (itemData.dataType == 'folder') {
    hasDownload = desktopStore.data.items.some(findItem => findItem.dataType == 'file' && findItem.parentUuid == itemData.uuid);
  }
  
  if (itemHovered && hasDownload) {
    itemDownloadHover = (
      <div className="item-download" onClick={(evt) => handleItemDownload(desktopStore, itemData)}>
        <img src={`${railsAssetsUrl}/download.svg`}/>
      </div>
    );
  }
  
  let itemSettingsModal;
    
  if (showItemSettingsModal) {
    itemSettingsModal = <ItemSettingsModal desktopStore={desktopStore} itemData={itemData} toggleItemTag={toggleItemTag} handleShowItemSettingsModal={handleShowItemSettingsModal} updatedItemData={updatedItemData} setUpdatedItemData={setUpdatedItemData} handleSetItemData={handleSetItemData} />;
  }
  
  const itemContent = (
    <ItemContent typeFontSize={typeFontSize} sizeFontSize={sizeFontSize} desktopStore={desktopStore} itemData={itemData} discardEdit={discardEdit} editMode={editMode} updatedItemData={updatedItemData} handleSetItemData={handleSetItemData} clickOutsideRef={clickOutsideRef} resizeStart={resizeStart} resizing={resizing} resizeStop={resizeStop} isDragging={isDragging} isHover={itemHovered} isResizing={isResizing} />
  );
  
  const icon = getItemIcon(itemData);
  
  const itemContainer = (
    <div draggable={!editMode} className={`item-container ${icon} ${itemData.color} ${thinness}` + (isActive ? ' highlight' : '')} onMouseEnter={() => setItemHovered(true)} onMouseLeave={() => { setItemHovered(false) } } ref={drag} style={{ left: itemData.left, top: itemData.top, ...getCustomItemDragStyles(isDragging) }} onClick={() => handleClick()} onDoubleClick={(evt) => handleDoubleClick(evt)}>
      {drop &&
      <div className="drop-area full-size" ref={drop}>
      	{itemContent}
      </div> }

      {!drop && 
      <div className="full-size">
      	{itemContent}
      </div> }
      
      {itemSettingsToggle}
      {itemDownloadHover}
      {itemSettingsModal}
    </div>
  );
  
  return itemContainer;
};

const containerStyles = {
    width: '3000px',
    height: '3000px',
    position: 'relative'
};

const Container = (props) => {
    const useItems = props.items || {};
    const [items, setItems] = useState(useItems);
    const scrollContainer = useRef(null);

    const uploadyContext = useContext(UploadyContext);
    
    props.desktopStore.setScrollContainer(scrollContainer);
    
    const [, drop] = useDrop({
      accept: ['item', 'folder'],
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        // console.log('container drop delta:' , delta);
        // console.log('container drop getSourceClientOffset:' , monitor.getSourceClientOffset());
        // console.log('monitor.getDropResult(), ', monitor.getDropResult());
        
        if (delta) {
          const left = Math.round(item.left + delta.x);
          const top = Math.round(item.top + delta.y);

          moveItem(item.id, left, top);
        }
        
        return undefined;
      }
    });
    
    const moveItem = (id, left, top) => {
      props.desktopStore.updateItem({uuid: id, x: left, y: top});
      
      setItems(update(items, {
        [id]: {
          $merge: { left, top },
        },
      }));
    };
    
    React.useEffect(() => {
      setItems(useItems);
    }, [useItems]);
    
    const state = useDropAndPaste({
      onFiles: files => {
        if (props.desktopStore.write) {
          const uploadFiles = files.map(file => {
            return file;
          });
          
          uploadyContext.upload(uploadFiles, {autoUpload: true});
        }
      },
      onUri: uri => {
        if (props.desktopStore.write) {
          props.desktopStore.addItem(null, {title: uri, data: uri, color: 'blue'}, 'item', 'url');
        }
      },
      onText: (text, evt) => {
        const activeTagName = document.activeElement.tagName.toUpperCase(); // Don't use 'evt'; if clicking next to input to lose focus, evt.target is still input
        
        if (activeTagName != 'INPUT' && activeTagName != 'TEXTAREA' && props.desktopStore.enablePaste && !props.desktopStore.searchMode && !props.desktopStore.editMode && !props.desktopStore.anyModalOpen && props.desktopStore.write) {
          if (text && text.trim().length) {
            props.desktopStore.addItem(null, {title: 'Untitled Note', data: text, color: 'yellow'}, 'item', 'note');
          }
        }
      },
    });
    
    const itemsContainer = (
      <div ref={drop} style={containerStyles} className="drag-container">
			{Object.keys(items).map((key) => {
        const itemData = items[key];

        return (
          <Item key={key} itemData={itemData} hideSourceOnDrag={props.hideSourceOnDrag} desktopStore={props.desktopStore} resizable={true} />
        );
      })}
      </div>
    );
    
    return (
      <ScrollContainer hideScrollbars={isMobile} ref={scrollContainer} ignoreElements=".item-container, .sc-gsTCUz, .changelog" style={{width: props.containerDimensions[0], height: props.containerDimensions[1]}}>
        {props.showActivity && <Changelog onClose={(evt) => { {evt.preventDefault(); props.desktopStore.setShowActivity(false);} }}/>}
        {itemsContainer}
  		</ScrollContainer>
    );
    
    // This scroll component has more functions such as pinch to zoom, but slightly more buggy:
    
    // return (
    //   <TransformWrapper wheel={{disabled: true}} options={{disabled: props.anyModalOpen, centerContent: true, limitToWrapper: false, limitToBounds: true}} pan={{disableOnTarget: [':not(.drag-container)']}} doubleClick={{disabled: true}}>
    //     <TransformComponent>
    //       {itemsContainer}
    //     </TransformComponent>
    //       </TransformWrapper>
    // );
};

const toggleRecentItems = (evt, desktopStore) => {
  if (evt) {
    evt.preventDefault();
  }

  if (desktopStore.sortBy) {
    desktopStore.setSortBy(false);
  } else {
    desktopStore.setSortBy('recent');
  }
  
  desktopStore.loadDesktop();
};

const toggleTagFilter = (evt, desktopStore, tag) => {
  if (evt) {
    evt.preventDefault();
  }
  
  if (tag) {
    desktopStore.toggleTag(tag);
  } else {
    desktopStore.clearToggledTags();
  }
  
  desktopStore.loadDesktop();
};

const toggleItemTag = (desktopStore, itemUuid, tag, preventUntoggle = true) => {
  if (!desktopStore.selectedTags.includes(tag) && desktopStore.searchMode) {
    toggleTagFilter(null, desktopStore, tag);
  }
  
  desktopStore.updateItem({uuid: itemUuid, tag, preventUntoggle});
};

const Tag = (props) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id: props.tag, type: 'tag' },
    canDrag: (dragProps) => {
      return props.desktopStore.write;
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        toggleItemTag(props.desktopStore, dropResult.id, props.tag, true);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  
  const removeTag = (evt) => {
    evt.preventDefault();
    
    if (confirm('You will remove this tag from all items. Are you sure?')) {
      props.desktopStore.deleteTag(props.tag);
    }
  };
  
  return (
    <div className="tag">
      <button type="button" className={'btn shadow-none ' + (props.toggled ? 'btn-dark toggled' : 'untoggled btn-outline-dark')} ref={drag} onClick={evt => toggleTagFilter(evt, props.desktopStore, props.tag)}>
        {props.tag}
      </button>
        
      <a className={'remove ' + (props.toggled ? 'toggled' : 'untoggled')} href="#" onClick={evt => removeTag(evt)}>
        x
      </a>
    </div>
  );
};

const toggleColorFilter = (evt, desktopStore, color) => {
  if (evt) {
    evt.preventDefault();
  }
  
  if (color) {
    desktopStore.toggleColor(color);
  } else {
    desktopStore.clearToggledColors();
  }
  
  desktopStore.loadDesktop();
};

const setItemColor = (desktopStore, itemUuid, color) => {
  if (!desktopStore.selectedColors.includes(color) && desktopStore.searchMode) {
    toggleColorFilter(null, desktopStore, color);
  }
  
  desktopStore.updateItem({uuid: itemUuid, color});
};

const Color = (props) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id: props.color, type: 'color' },
    canDrag: (dragProps) => {
      return props.desktopStore.write;
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        setItemColor(props.desktopStore, dropResult.id, props.color);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
  });
  
  return (
    <div className={`${props.color} ` + (props.toggled ? ' selected' : '')} ref={drag} onClick={evt => toggleColorFilter(evt, props.desktopStore, props.color)} />
  );
};

const getItemIcon = (itemData) => {
  let icon;

  if (itemData.generalType == 'folder') {
    icon = 'folder';
  } else if (itemData.dataType == 'note') {
    icon = 'note';
  } else if (itemData.image) {
    icon = 'image';
  } else if (itemData.dataType == 'file') {
    icon = 'file';
  } else if (itemData.dataType == 'url') {
    icon = 'url';
  } else {
    icon = 'file';
  }
  
  return icon;
};

const getItemThinness = (itemData, width, height) => {
  let thinness = '';

  const useWidth = width || itemData.width;
  const useHeight = height || itemData.height;

  if (itemData.generalType == 'folder') {
    if (useWidth > 240) {
      thinness = 'extra-thin';
    } else if (useWidth > 120) {
      thinness = 'thin';
    }
  } else if (itemData.dataType == 'note') {
    if (useWidth > 320) {
      thinness = 'extra-thin';
    } else if (useWidth > 170) {
      thinness = 'thin';
    }
  } else if (itemData.image) {
    if (useWidth > 320) {
      thinness = 'extra-thin';
    } else if (useWidth > 170) {
      thinness = 'thin';
    }
  } else if (itemData.dataType == 'file') {
    if (useWidth > 320) {
      thinness = 'extra-thin';
    } else if (useWidth > 170) {
      thinness = 'thin';
    }
  } else if (itemData.dataType == 'url') {
    if (useWidth > 320) {
      thinness = 'extra-thin';
    } else if (useWidth > 170) {
      thinness = 'thin';
    }
  } else {
    if (useWidth > 320) {
      thinness = 'extra-thin';
    } else if (useWidth > 170) {
      thinness = 'thin';
    }
  }
  
  return thinness;
};

// TODO refactor so that all props setAddItemData checkAddUrlInput etc are contained within component. Code now message because of translation from class -> function
const AddUrlDropdown = (props) => {
  const clickOutsideAddUrlRef = useOnclickOutside(async () => {
    props.clickOutside();
  });  

  return (
    <div className="btn-group" ref={clickOutsideAddUrlRef}>
      <button className="btn btn-dark desktop-action-btn dropdown-toggle" type="button" aria-haspopup="true" aria-expanded="false" onClick={() => { props.toggleAddUrl() }}>
        <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#external-link'}/></svg>
        Add URL
      </button>
  
      { props.showAddUrl && 
      <div id="add-url" className="dropdown-menu" style={{display: 'block'}}>
        <div className="input-group">
          <input autoFocus={true} type="text" className="form-control" id="url" placeholder="Enter URL" onChange={props.setAddItemData.bind(this)} onKeyDown={(evt) => props.checkAddUrlInput(evt, props.desktopStore)} />
      
          <div className="input-group-append">
            <button type="button" className="btn btn-dark" onClick={(evt) => props.addUrl(evt, props.desktopStore)}>Add</button>
          </div>
        </div>
      </div> }
    </div>
  );
  
};

const UploadFile = (props) => {
  const uploadyContext = useContext(UploadyContext);

  useEffect(() => {
      return () => {
        uploadyContext.abort();
      }
  }, []);
      
  return (
    <div className="btn-group">
      <button className="btn btn-dark desktop-action-btn" onClick={evt => {uploadyContext.showFileUpload();}}>
        <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#upload'}/></svg>
        Upload Files
      </button>
    </div>
  );
};

const Desktop = observer(
  class Desktop extends React.Component {
    static contextType = RouterContext;
    
    constructor(props) {
      super(props);
      
      this.state = {
        itemData: {},
        showAddUrl: false,
        showMembersModal: false,
        showSettingsModal: false,
        addNewTag: '',
        containerDimensions: []
      };
    }
    
    componentDidMount() {
      if (isMobile) {
        window.scrollTo(0, 0);
      }

      this.recalculateContainerDimensions();
      
      document.getElementById('navbar-toggle-1').addEventListener('click', this.recalculateContainerDimensions.bind(this, 1000));
      document.getElementById('navbar-toggle-2').addEventListener('click', this.recalculateContainerDimensions.bind(this, 1000));

      window.addEventListener('resize', this.recalculateContainerDimensions.bind(this));
    }
    
    recalculateContainerDimensions(timeout) {
      if (timeout) {
        setTimeout(() => {
          this.setState({containerDimensions: this.getContainerDimensions()});
        }, timeout);
      } else {
        this.setState({containerDimensions: this.getContainerDimensions()});
      }
    }
    
    getContainerDimensions() {
      const navigator = document.getElementById('navigator');
      const desktopMenu = document.getElementById('desktop-menu-container');
      
      let height = 25;
      
      if (navigator) {
        height = height + navigator.offsetHeight;
      }
      
      if (desktopMenu) {
        height = height + desktopMenu.offsetHeight;
      }
      
      const useWidth = 'calc(100vw)';
      const useHeight = `calc(100vh - ${height}px)`;

      return [useWidth, useHeight];
    }
    
    componentDidUpdate(prevProps, prevState) {
      if (this.state.showMembersModal != prevState.showMembersModal) {
        this.context.options.desktopStore.setPaste(!this.state.showMembersModal);
        this.context.options.desktopStore.setAnyModalOpen(this.state.showMembersModal);
      } else if (this.state.showSettingsModal != prevState.showSettingsModal) {
        this.context.options.desktopStore.setPaste(!this.state.showSettingsModal);
        this.context.options.desktopStore.setAnyModalOpen(this.state.showSettingsModal);
      }
    }
    
    toggleMembersModal(evt) {
      if (evt) {
        evt.preventDefault();
      }
      
      this.setState((state, props) => {
        return {showMembersModal: !state.showMembersModal};
      });
    }
    
    toggleSettingsModal(evt) {
      if (evt) {
        evt.preventDefault();
      }
      
      this.setState((state, props) => {
        return {showSettingsModal: !state.showSettingsModal};
      });
    }
    
    setAddItemData(evt) {
      this.setState((state, props) => {
        const itemData = state.itemData;
        itemData.data = evt.target.value;
        return {itemData: itemData};
      });
    }
    
    toggleAddUrl() {
      this.setState((state, props) => {
        this.context.options.desktopStore.setPaste(state.showAddUrl);
        return {itemData: {}, showAddUrl: !state.showAddUrl};
      });
    }
    
    checkAddUrlInput(evt, desktopStore) {
      if (evt && evt.key == 'Enter') {
        this.addUrl(evt, desktopStore);
      } else if (evt && evt.key == 'Escape') {
        this.setState({showAddUrl: false});
      }
    }
    
    addUrl(evt, desktopStore) {
      desktopStore.addItem(evt, {title: this.state.itemData.data, color: 'blue', data: this.state.itemData.data}, 'item', 'url', () => {
        this.setState({showAddUrl: false});
      });
    }

    setAddNewTag(addNewTag) {
      this.setState({addNewTag});
    }
    
    checkAddNewTagKey(evt) {
      if (evt && evt.key == 'Enter') {
        this.doAddNewTag();
      } else if (evt && evt.key == 'Escape') {
        this.setAddNewTag('');
      }
    }
    
    doAddNewTag() {
      if (this.state.addNewTag && this.state.addNewTag.trim().length > 0) {
        this.context.options.desktopStore.addTag(this.state.addNewTag.trim());
        this.setAddNewTag('');
      }
    }
    
    setSearchByText(searchByText, desktopStore) {
      const resetSearch = desktopStore.searchBy && desktopStore.searchByText != searchByText && !searchByText;
      desktopStore.setSearchByText(searchByText);
      
      if (resetSearch) {
        desktopStore.setSearchBy(false);
        desktopStore.loadDesktop();
      }
    }
    
    checkSearchByTextKey(evt, desktopStore) {
      if (evt && evt.key == 'Enter') {
        if (desktopStore.searchByText) {
          desktopStore.setSearchBy(true);
          desktopStore.loadDesktop();
        }
      } else if (evt && evt.key == 'Escape') {
        const resetSearch = desktopStore.searchBy;
        
        if (resetSearch) {
          desktopStore.setSearchBy(false);
          desktopStore.setSearchByText('');
          desktopStore.loadDesktop();
        }
      }
    }
    
    render() {
      const desktopStore = this.context.options.desktopStore;
      const desktopData = desktopStore.data;
      
      let statusContent;
      
      if (desktopData.loading) {
        statusContent = <b className="loading"><svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#watch'}/></svg> Loading workspace...</b>;
      }
  
      if (desktopData.loadError) {
        if (desktopData.loadError.error == 'not_found') {
          statusContent = (
            <section className="fdb-block">
              <div className="container">
                <div className="row align-items-center pt-2 pt-lg-5">
                  <div className="col-12 col-md-8 col-lg-7">
                    <h2>Workspace not found</h2>
                    <p className="lead">Workspace "{desktopData.loadError.shortcode}" was not found, it may have been removed. Double check the URL.</p>
                    <p className="mt-4">
                      <RouterLink routeName="createDesktop" className="btn btn-primary">Create new workspace</RouterLink>
                    </p>
                  </div>
    
                  <div className="col-8 col-md-4 m-auto m-md-0 ml-md-auto pt-5">
                    <img alt="image" className="img-fluid" src={`${railsAssetsUrl}/draws/git.svg`}/>
                  </div>
                </div>
              </div>
            </section>          
          );
        } else if (desktopData.loadError.error == 'access_denied') {
          statusContent = (
            <section className="fdb-block">
              <div className="container">
                <div className="row align-items-center pt-2 pt-lg-5">
                  <div className="col-12 col-md-8 col-lg-7">
                    <h2>Access Denied</h2>
                    <p className="lead">You don't have access to this workspace. You need to ask the owner to invite you as a member first.</p>
                  </div>
    
                  <div className="col-8 col-md-4 m-auto m-md-0 ml-md-auto pt-5">
                    <img alt="image" className="img-fluid" src={`${railsAssetsUrl}/draws/tabs.svg`}/>
                  </div>
                </div>
              </div>
            </section>          
          );
        }
      }
      
      const items = desktopData.items || [];
      const dndItems = {};
      
      const parentItemUuid = this.context.routerState.params.parentId;
      let parentItem;
      
      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      
      let ratio = 1.0;
      
      if (viewportWidth < 768) {
        ratio = 0.70;
      }
      
      let searchContainerPaddingLeft = Math.ceil(50 * ratio);
      let searchContainerPaddingTop = Math.ceil(50 * ratio);
      
      let searchItemWidth = Math.ceil(100 * ratio);
      let searchItemHeight = Math.ceil(100 * ratio);
      let searchItemPaddingWidth = Math.ceil(50 * ratio);
      let searchItemPaddingHeight = Math.ceil(100 * ratio);
      
      if (viewportWidth < 768) {
        searchContainerPaddingLeft = 20;
        searchContainerPaddingTop = 20;

        searchItemPaddingWidth = 40;
        searchItemPaddingHeight = 75;
      }
      
      const searchItemsPerRow = Math.floor((viewportWidth - (searchContainerPaddingLeft * 2) - (searchItemWidth + searchItemPaddingWidth)) / (searchItemWidth + searchItemPaddingWidth));
      
      items.forEach((item, itemIdx) => {
        let actualItemWidth;
        let actualItemHeight;
        
        if (item.dataType == 'folder') {
          // Original:
          // width = 114
          // height = 95
          actualItemWidth = Math.ceil(120 * ratio);;
          actualItemHeight = Math.ceil(100 * ratio);
        } else if (item.image) {
          // Original:
          // width = 92
          // height = 77
          actualItemWidth = Math.ceil(120 * ratio);;
          actualItemHeight = Math.ceil(100 * ratio);;
        } else if (item.dataType == 'note') {
          // Original:
          // width = 145
          // height = 137
          actualItemWidth = Math.ceil(106 * ratio);;
          actualItemHeight = Math.ceil(100 * ratio);;
        } else if (item.dataType == 'file') {
          // Original:
          // width = 129
          // height = 171
          actualItemWidth = Math.ceil(76 * ratio);;
          actualItemHeight = Math.ceil(100 * ratio);;
        } else if (item.dataType == 'url') {
          // Original:
          // width = 52
          // height = 52
          actualItemWidth = Math.ceil(100 * ratio);;
          actualItemHeight = Math.ceil(100 * ratio);;
        }
        
        if (parentItemUuid && item.uuid == parentItemUuid) {
          parentItem = item;
        }
        
        if (desktopStore.searchMode) {
          dndItems[item.uuid] = {
            ...item,
            width: actualItemWidth,
            height: actualItemHeight,
            left: searchContainerPaddingLeft + ((itemIdx % searchItemsPerRow) * (searchItemWidth + searchItemPaddingWidth)),
            top: searchContainerPaddingTop + (Math.floor(itemIdx / searchItemsPerRow) * (searchItemHeight + searchItemPaddingHeight))
          };
        } else {
          if ((!parentItemUuid && !item.parentUuid) || (parentItemUuid && parentItemUuid == item.parentUuid)) {
            dndItems[item.uuid] = {
              ...item,
              left: item.x,
              top: item.y
            };
          }
        }
      });
      
      const addUrlDropdown = <AddUrlDropdown desktopStore={desktopStore} addUrl={this.addUrl.bind(this)} toggleAddUrl={this.toggleAddUrl.bind(this)} checkAddUrlInput={this.checkAddUrlInput.bind(this)} setAddItemData={this.setAddItemData.bind(this)} showAddUrl={this.state.showAddUrl} clickOutside={() => { this.setState({showAddUrl: false}); }} />;
      
      const addFolder = (
        <div className="btn-group">
          <button className="btn btn-dark desktop-action-btn" type="button" onClick={() => { desktopStore.addItem(null, {title: 'Untitled Folder', color: 'blue'}, 'folder', 'folder') }}>
            <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#folder'}/></svg>
            Add Folder
          </button>
        </div>
      );

      const addNote = (
        <div className="btn-group">
          <button className="btn btn-dark desktop-action-btn" type="button" onClick={() => { desktopStore.addItem(null, {title: 'Untitled Note', color: 'yellow', data: ''}, 'item', 'note') }}>
            <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#file'}/></svg>
            Add Note
          </button>
        </div>
      );

      let membersModal;
      
      if (desktopData.uuid) {
        membersModal = (
          <>
            <a href="#" className="desktop-btn" aria-haspopup="true" aria-expanded="false" onClick={(evt) => { {evt.preventDefault(); this.toggleMembersModal();} }}>
              <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#users'}/></svg>
              {desktopData.anonymous ? 'Share' : 'Members'}
            </a>        
        
            { this.state.showMembersModal && 
              <MembersModal onClose={(evt) => this.toggleMembersModal(evt) } />
            }
          </>
        );
      }

      let settingsModal;

      if (desktopData.uuid && desktopStore.admin) {
        settingsModal = (
          <>
            <a href="#" className="desktop-btn" aria-haspopup="true" aria-expanded="false" onClick={(evt) => { {evt.preventDefault(); this.toggleSettingsModal();} }}>
              <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#tool'}/></svg>
              Settings
            </a>        
        
            { this.state.showSettingsModal && 
              <DesktopSettingsModal desktopStore={desktopStore} onCustomUrlChange={() => this.toggleSettingsModal()} onClose={(evt) => this.toggleSettingsModal(evt) } />
            }
          </>
        );
      }

      let showActivity;
      
      if (desktopData.uuid) {
        showActivity = (
          <>
            <a href="#" className="desktop-btn" aria-haspopup="true" aria-expanded="false" onClick={(evt) => { {evt.preventDefault(); (desktopStore.showActivity ? desktopStore.setShowActivity(false) : desktopStore.setShowActivity(true))} }}>
              <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#watch'}/></svg>
              Activity
            </a>        
          </>
        );
      }

      let tagsContent;
      
      if (!statusContent) {
        const colors = ['blue', 'green', 'yellow', 'red', 'purple'];

        tagsContent = (
          <div className="container">
            <div className="row">
          
              <div className="col">
                <div className="filter-container">
                  <div className="color-filter">
                    { false && <div className={desktopStore.selectedColors.length < 1 ? 'selected all' : 'all'} onClick={evt => toggleColorFilter(evt, desktopStore, null)}></div>}
  
                    { colors.map(color => 
                      <Color key={color} desktopStore={desktopStore} desktopUuid={desktopData.uuid} color={color} toggled={desktopStore.isColorToggled(color)} />
                    )}
                  </div>

                  <div className="tags-container desktop-filter">
                    <div className="tags-filter">
                      <div className="tag">
                        <button type="button" className={'btn shadow-none ' + (desktopStore.sortBy && desktopStore.sortBy.length > 0 ? 'btn-dark toggled' : 'untoggled btn-outline-dark')} onClick={evt => toggleRecentItems(evt, desktopStore)}>
                          <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#clock'}/></svg>
                          Recent Items
                        </button>
                      </div>
          
                      { false && <a href="#" onClick={evt => toggleTagFilter(evt, desktopStore, null)}>{desktopStore.selectedTags.length < 1 && <span>✅ </span>} All Tags</a> }

                      { desktopData.availableTags.map(tag => 
                        <Tag key={tag} desktopStore={desktopStore} desktopUuid={desktopData.uuid} tag={tag} toggled={desktopStore.isTagToggled(tag)} />
                      )}
                    </div>
                  
                    <div className="input-group">
                      <input type="text" className="add-new-tag form-control" value={this.state.addNewTag} onChange={evt => this.setAddNewTag(evt.target.value)} placeholder="Add new tag" onKeyDown={evt => this.checkAddNewTagKey(evt)} />
      
                      <div className="input-group-append">
                        <button type="button" className="btn btn-dark" onClick={evt => this.doAddNewTag()}>Add tag</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      
      let folderPaths = [];
      let findParentUuid = parentItemUuid;
      
      while (findParentUuid) {
        const parentOfParent = desktopStore.data.items.find(findItem => findItem.uuid == findParentUuid);
        
        if (parentOfParent) {
          folderPaths.push({title: parentOfParent.title, parentUuid: parentOfParent.uuid});
          findParentUuid = parentOfParent.parentUuid;
        } else {
          break;
        }
      }
      
      folderPaths.push({title: 'Root', parentUuid: null});
      folderPaths.reverse();
      
      if (folderPaths.length > 4) {
        const truncatedFolderPaths = []
        
        truncatedFolderPaths[0] = folderPaths[0];
        truncatedFolderPaths[1] = folderPaths[1];
        truncatedFolderPaths[2] = {truncated: true};
        truncatedFolderPaths[3] = folderPaths[folderPaths.length - 2];
        truncatedFolderPaths[4] = folderPaths[folderPaths.length - 1];
        
        folderPaths = truncatedFolderPaths;
      }

      const desktopMenu = (
        <div id="desktop-menu-container">
          {statusContent &&
          <div className="container">
            <div className="row">
              <div className="col">
                {statusContent}
              </div>
            </div>
          </div> }

          {!statusContent &&
          <div className="container">
            <div className="row">
        
              <div className="col desktop-actions">
                { desktopStore.write && <>
                  {addNote} {addUrlDropdown} {addFolder} <UploadFile />
                </> }
              </div>
            
              <div className="col-12 col-xl-5">
                <div className="desktop-options">
                  {showActivity}
                  {membersModal}
                  {desktopStore.admin && settingsModal}
                  <input className="search-input form-control" value={desktopStore.searchByText ? desktopStore.searchByText : ''} onChange={evt => this.setSearchByText(evt.target.value, desktopStore)} placeholder="Search" onKeyDown={evt => this.checkSearchByTextKey(evt, desktopStore)} />
                </div>
              </div>
            
            </div>
          </div> }
      
          {tagsContent}
        </div>
      );

      const dndBackend = isMobile ? TouchBackend : HTML5Backend;
      let dndOptions = {};
      
      if (isMobile) {
        dndOptions = {
          enableHoverOutsideTarget: false,
          delay: 20
        };
      }
      
      const content = (
        <div className="desktop-container">
          <DndProvider backend={dndBackend} options={dndOptions}>
            <div className={"collapse show navbar-collapse navbar-collapse-3 justify-content-end"} id="navbarNav8">
              {desktopMenu}
            </div>
              
            {!desktopStore.searchMode && folderPaths.length > 1 && 
            <div className="container-menu">
              {
                folderPaths.map((path, idx) => 
                  <span key={path.truncated ? 'truncated' : (path.parentUuid || 'main')}>
                    {path.truncated && <span>...</span>}
                    {!path.truncated && path.parentUuid != parentItemUuid && <RouterLink routeName={path.parentUuid ? 'desktopWithParent' : 'desktop'} params={path.parentUuid ? {id: desktopStore.data.shortcode, parentId: path.parentUuid} : {id: desktopStore.data.shortcode}}>{path.parentUuid ? path.title : 'Top'}</RouterLink>}
                    {!path.truncated && path.parentUuid == parentItemUuid && <span className="current-path">{path.title}</span>}
                    {(idx < folderPaths.length - 1) && <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#arrow-right-circle'}/></svg>}
                  </span>
                )
              }
            </div>}

            {desktopStore.searchMode && 
            <div className="reset-search">
              <span>{parseFloat(Object.keys(dndItems).length).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} items found</span>
              <a href="#" onClick={evt => desktopStore.resetSearch(evt)}><svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#x-circle'}/></svg> Clear search filters</a>
            </div>}
    
            {!statusContent &&
            <div id="container-container" className={'drag-container' + (desktopStore.searchMode ? ' search-results' : '') + (folderPaths.length > 1 ? '' : ' container-border')}>
              {!desktopStore.searchLoading &&
              <Container showActivity={desktopStore.showActivity} containerDimensions={this.state.containerDimensions} hideSourceOnDrag={true} items={dndItems} desktopStore={desktopStore} anyModalOpen={desktopStore.anyModalOpen} /> }
            
              <CustomItemDragLayer desktopStore={desktopStore} />
            </div> }
  				</DndProvider>
        </div>
      );
    
      return <App desktopStore={desktopStore} fullSize={true}>{content}</App>;
    }
  }
);

export default Desktop;