import React from 'react';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

class MembersModal extends React.Component {
  static contextType = RouterContext;

  constructor(props) {
    super(props);
  
    this.state = this.initialState();
  }

  initialState() {
    return {
      emails: '',
      role: 'write',
      error: null,
      success: null
    };
  }

  setEmails(emails) {
    this.setState({emails});
  }

  setRole(role) {
    this.setState({role});
  }

  async invite() {
    const error = await this.context.options.desktopStore.invite(this.state.emails, this.state.role);
    this.setState(this.initialState());
    
    if (error) {
      this.setState({error});
    } else {
      this.setState({success: 'Invites sent!'});
    }
  }

  async deleteMember(memberId) {
    const error = await this.context.options.desktopStore.deleteMember(memberId);
    
    this.setState({error});
  }

  async leaveDesktop() {
    const member = this.context.options.desktopStore.data.members.find(member => member.user.userUuid == this.context.options.userStore.data.userUuid);
    
    if (member) {
      if (member.role == 'admin') {
        const admins = this.context.options.desktopStore.data.members.filter(member => member.role == 'admin');

        if (admins.length < 2) {
          alert('You cannot leave this workspace as the only admin.\nFirst make another member admin before you leave.');
        } else {
          if (confirm('Are you sure you want to leave this workspace?')) {
            await this.context.options.desktopStore.deleteMember(member.id);
            this.context.options.desktopStore.rootStore.goTo('home');
          }
        }
      } else {
        if (confirm('Are you sure you want to leave this workspace?')) {
          await this.context.options.desktopStore.deleteMember(member.id);
          this.context.options.desktopStore.rootStore.goTo('home');
        }
      }
    }
  }

  render() {
    const userStore = this.context.options.userStore;
    const desktopStore = this.context.options.desktopStore;
    const desktopData = desktopStore.data;
    
    const batchSize = 2;
    const batches = [];
    
    for (let i = 0; i < desktopData.members.length; i += batchSize) {
      batches.push(desktopData.members.slice(i, i + batchSize));
    }
    
    const membersList = (
      <section className="fdb-block team-7 p-0">
        <div className="container">
          { batches.map((members, idx) => <>
            <div key={`batch-${idx}`} className="row justify-content-center">
          
            {members.map(member => 
              <div key={member.id} className={`col-sm-${12/members.length} m-sm-auto`}>
                <div className="row align-items-center">
                  <div className="col-3">
                    <img alt="image" className="img-fluid rounded-circle" src={`${railsAssetsUrl}/content/user.svg`}/>
                  </div>
  
                  <div className="col-9">
                    <h3 className="member-name">{member.user.email}</h3>
                    <p className="mb-1">Role: {member.role == 'admin' ? 'Admin' : (member.role == 'write' ? 'Read & Write' : 'Read Only')}</p>
                    { desktopStore.admin && <p>
                    <a href="#" onClick={(evt) => { evt.preventDefault(); this.deleteMember(member.id) }}>Delete Member</a> </p> }
                  </div>
                </div>
              </div>
            )}
  
            </div>
  
            <div className="row-50"></div>
          </> )}

        </div>
      
        <hr/>
      </section>
    );
    
    const title = desktopData.anonymous ? 'Share' : 'Members';
    
    return (
      <div className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(evt) => this.props.onClose(evt)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              { desktopData.members.length > 0 && membersList }
    
              { desktopStore.admin && <>
                <div className="container">
                  <div className="row mt-4">
                    <div className="col">
                      <textarea className="form-control" placeholder="Enter one or more emails separated by spaces." rows={3} style={{width: '100%', height: '100px'}} autoFocus={true} value={this.state.emails} onChange={(evt) => this.setEmails(evt.target.value)} />
                    </div>
                  </div>
              
                  { !desktopData.anonymous &&
                  <div className="row mt-4">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="role">Role for invited members:</label>
                  
                        <select id="role" className="form-control" value={this.state.role} onChange={(evt) => this.setRole(evt.target.value)} onMouseDown={(evt) => {evt.stopPropagation()}} onMouseUp={(evt) => {evt.stopPropagation()}}>
                          <option value="admin">Admin</option>
                          <option value="write">Read & Write</option>
                          <option value="read">Read Only</option>
                        </select>
                      </div>
                    </div>
                  </div> }

                </div>
              </> }
              
              { userStore.data.userUuid && desktopData.members.length > 1 &&
                <div className="container">
                  <div className="row mt-4">
                    <div className="col text-right">
                      <a href="#" onClick={(evt) => { evt.preventDefault(); this.leaveDesktop(); }}>Leave this workspace</a>
                    </div>
                  </div>
                </div>
              }
              
            </div>
      
            <div className="container">
              <div className="col">
                {this.state.error &&
                <div className="alert alert-danger" role="alert">
                  {this.state.error}
                </div> }

                {this.state.success &&
                <div className="alert alert-success" role="success">
                  {this.state.success}
                </div> }
              </div>
            </div>
      
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={(evt) => this.props.onClose(evt)}>Cancel</button>
              { desktopStore.admin &&
              <button type="button" className="btn btn-primary" onClick={(evt) => this.invite()}>Send Invites</button> }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MembersModal;