import React from 'react';
import { createRouterState, RouterState, RouterStore } from 'mobx-state-router';

import Home from '../components/Home';
import Login from '../components/Login';
import Account from '../components/Account';
import Signup from '../components/Signup';
import ResetPassword from '../components/ResetPassword';
import ChangePassword from '../components/ChangePassword';
import Pricing from '../components/Pricing';
import CreateDesktop from '../components/CreateDesktop';
import Desktop from '../components/Desktop';
import Contact from '../components/Contact';
import RequestPlan from '../components/RequestPlan';
import NotFound from '../components/NotFound';

const checkForUserLoggedIn = async (fromState, toState, routerStore) => {
  const userStore = routerStore.options.userStore;
  
  if (!userStore.data.userUuid) {
    await userStore.getCurrentUser();
  }
  
  if (userStore.data.userUuid) {
    analyticsIdentify(userStore.data.userUuid);
  }
};

const checkForUserLoggedInAndOpenDefaultDesktop = async (fromState, toState, routerStore) => {
  const userStore = routerStore.options.userStore;
  
  if (!userStore.data.userUuid) {
    await userStore.getCurrentUser();
  }
  
  if (userStore.data.userUuid) {
    analyticsIdentify(userStore.data.userUuid);
  }
  
  if (userStore.data.userUuid) {
    const desktops = await routerStore.options.desktopStore.getDesktops();
    let defaultDesktop = desktops.find(desktop => desktop.default);
    
    if (!defaultDesktop) {
      defaultDesktop = desktops[0];
    }
    
    if (defaultDesktop) {
      return routerStore.goToState(createRouterState('desktop', {params: {id: defaultDesktop.shortcode}}));
    } else {
      // return routerStore.goToState(createRouterState('createDesktop'));
    }
  }
};

const checkForUserLoggedInWithRedirect = async (fromState, toState, routerStore) => {
  const userStore = routerStore.options.userStore;
  
  if (!userStore.data.userUuid) {
    await userStore.getCurrentUser();
    
    if (!userStore.data.userUuid) {
      userStore.setLoggedInRedirect(toState); // store toState so we can redirect after login
      return createRouterState('login');
    }
  }
};

const checkForUserNotLoggedIn = async (fromState, toState, routerStore) => {
  const userStore = routerStore.options.userStore;
  
  if (userStore.data.userUuid) {
    return createRouterState('home');
  }
};

const prepareDesktopData = async (fromState, toState, routerStore) => {
  routerStore.options.desktopStore.init({showActivity: toState.queryParams && toState.queryParams.activity === null});
  
  await routerStore.options.desktopStore.loadDesktop(toState.params.id);
  await routerStore.options.desktopStore.getDesktops();
  
  if (routerStore.options.desktopStore.data.uuid) {
    routerStore.options.websocketStore.startListenDesktop(routerStore.options.desktopStore.data.uuid, routerStore);
  }  
};

// Note: "beforeEnter: checkForUserLoggedIn" everywhere below is so that it has user session on fresh load from that url.
export const routes = [
  {
    name: 'login',
    pattern: '/login',
    beforeEnter: checkForUserNotLoggedIn,
    onEnter: async () => {
      analyticsTrack('view-login');
    }
  },
  {
    name: 'signup',
    pattern: '/signup',
    beforeEnter: checkForUserNotLoggedIn,
    onEnter: async () => {
      analyticsTrack('view-signup');
    }
  },
  {
    name: 'resetPassword',
    pattern: '/reset-password',
    beforeEnter: checkForUserNotLoggedIn
  },
  {
    name: 'changePassword',
    pattern: '/change-password/:token',
    beforeEnter: checkForUserNotLoggedIn
  },
  {
    name: 'contact',
    pattern: '/contact',
    onEnter: async () => {
      analyticsTrack('view-contact');
    }
  },
  {
    name: 'requestPlan',
    pattern: '/plan/:plan_type',
    onEnter: async () => {
      analyticsTrack('view-request-plan');
    }
  },
  {
    name: 'logout',
    pattern: '/logout',
    onEnter: async(fromState, toState, routerStore) => {
      await routerStore.options.userStore.logout();
      
      routerStore.options.userStore.init();
      routerStore.options.desktopStore.init();
      
      return routerStore.goToState(createRouterState('home'));
    }
  },
  {
    name: 'account',
    pattern: '/account',
    beforeEnter: checkForUserLoggedInWithRedirect,
    onEnter: async () => {
      analyticsTrack('view-account');
    }
  },
  {
    name: 'home',
    pattern: '/',
    beforeEnter: checkForUserLoggedInAndOpenDefaultDesktop,
    onEnter: async () => {
      analyticsTrack('view-home');
    }
  },
  {
    name: 'createDesktop',
    pattern: '/create',
    beforeEnter: checkForUserLoggedIn,
    onEnter: async () => {
      analyticsTrack('view-create-desktop');
    }
  },
  {
    name: 'desktop',
    pattern: '/d/:id',
    beforeEnter: checkForUserLoggedIn,
    onEnter: async(fromState, toState, routerStore) => {
      analyticsTrack('open-desktop');
      
      if (fromState.routeName != 'desktopWithParent') {
        await prepareDesktopData(fromState, toState, routerStore);
      }
    },
    onExit: async(fromState, toState, routerStore) => {
      if (toState.routeName != 'desktopWithParent' && toState.routeName != 'desktop') {
        routerStore.options.websocketStore.stopListenDesktop(routerStore.options.desktopStore.data.uuid, routerStore);
      }
    }
  },
  {
    name: 'desktopWithParent',
    pattern: '/d/:id/:parentId',
    beforeEnter: checkForUserLoggedIn,
    onEnter: async(fromState, toState, routerStore) => {
      if (!routerStore.options.desktopStore.data.uuid) {
        await prepareDesktopData(fromState, toState, routerStore);
      }
    },
    onExit: async(fromState, toState, routerStore) => {
      if (toState.routeName != 'desktopWithParent' && toState.routeName != 'desktop') {
        routerStore.options.websocketStore.stopListenDesktop(routerStore.options.desktopStore.data.uuid, routerStore);
      }
    }
  },
  {
    name: 'invitation',
    pattern: '/invitation/:invitationUuid',
    beforeEnter: checkForUserLoggedInWithRedirect,
    onEnter: async(fromState, toState, routerStore) => {
      const result = await routerStore.options.desktopStore.acceptInvite(toState.params.invitationUuid);
      routerStore.options.userStore.setLoggedInRedirect(null);

      if (result.error) {
        alert(result.error);
        return routerStore.goToState(createRouterState('home'));
      } else {
        return routerStore.goToState(createRouterState('desktop', {params: {id: result.shortcode}}));
      }
    }
  },
  {
    name: 'pricing',
    pattern: '/pricing',
    onEnter: async () => {
      analyticsTrack('view-pricing');
    }
  },
  {
    name: 'notFound',
    pattern: '/not-found'
  }
];

export const viewMap = {
    login: <Login />,
    signup: <Signup />,
    resetPassword: <ResetPassword />,
    changePassword: <ChangePassword />,
    account: <Account />,
    contact: <Contact />,
    requestPlan: <RequestPlan />,
    home: <Home />,
    createDesktop: <CreateDesktop />,
    desktop: <Desktop />,
    desktopWithParent: <Desktop />,
    pricing: <Pricing />,
    notFound: <NotFound />,
};