// From: https://codepen.io/codiechanel/pen/oqzKKw

import React from 'react';
import {observable, decorate, makeObservable} from 'mobx';

class WebsocketStore {
  cableApp;
  data;

  constructor(cableApp) {
    this.cableApp = cableApp;
    
    makeObservable(this, {
      data: observable
    });

    // Done instead via mobx-state-router onEnter/onExit hooks:
    // onBecomeObserved(this, 'data', this.startListen);
    // onBecomeUnobserved(this, 'data', this.stopListen);
  }
  
  startListenDesktop(id, routerStore) {
    let currentIdentifier;
    
    if (this.cableApp.currentDesktopSubscription) {
      currentIdentifier = JSON.parse(this.cableApp.currentDesktopSubscription.identifier);
      
      if (id != currentIdentifier.id) {
        this.stopListenDesktop(id, routerStore);
        currentIdentifier = null;
      }
    }
    
    if (!currentIdentifier || (currentIdentifier && currentIdentifier.id != id)) {
      // console.log("start listen desktop", id);
      
      this.cableApp.currentDesktopSubscription = this.cableApp.cable.subscriptions.create(
        {
          channel: 'DesktopChannel',
          id: id
        },
        {
          received: (data) => {
            if (data.by_user_or_anonymous_uuid != routerStore.options.userStore.data.userOrAnonymousUuid) {
              // console.log('Updating desktop channel store.');
              // console.log('Receive desktop channel data', data);
            
              routerStore.options.desktopStore.setData(JSON.parse(data.data));
            }
          }
        }
      );
    }
  }
  
  stopListenDesktop(id, routerStore) {
    if (this.cableApp.currentDesktopSubscription) {
      // console.log("close desktop listen: ", this.cableApp.currentDesktopSubscription);
      routerStore.options.desktopStore.setShowUploaderPreview(false);

      this.cableApp.currentDesktopSubscription.unsubscribe();
      this.cableApp.currentDesktopSubscription = null;
    }
  }
}

export default WebsocketStore;