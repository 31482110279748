import React, { useState, useEffect, useRef, useCallback, memo, useContext } from 'react';
import {RouterLink} from 'mobx-state-router';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

import App from './App';

const ResetPassword = observer(
  class ResetPassword extends React.Component {
    static contextType = RouterContext;
  
    constructor(props) {
      super(props);
    
      this.state = {
        email: ''
      };
    }
  
    setEmail(email) {
      this.setState({email});
    }

    async resetPassword() {
      this.setState({error: null, success: null});
      const error = await this.context.options.userStore.resetPassword(this.state.email);

      if (!error) {
        this.setState({success: 'Check your e-mail for password reset instructions.'});
      } else {
        this.setState({error});
      }
    }

    render() {
      return (
        <App>
        
          <section className="fdb-block py-0">
            <div id="login-form" className="container py-5 my-5">
              <div className=" row justify-content-end">
                <div className="col-12 col-md-8 col-lg-6 col-xl-5 text-left">
                  <div className="fdb-box">
                    <div className="row">
                      <div className="col">
                        <h1>Reset Password</h1>
                        {false && <p className="lead">Right at the coast of the Semantics, a large language ocean. A small river named Duden.</p>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-4">
                        <input type="text" autoFocus={true} className="form-control" onChange={evt => this.setEmail(evt.target.value)} value={this.state.email} placeholder="Your email" autoFocus={true}/>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <button className="btn btn-dark" type="button" onClick={()=>this.resetPassword()}>Reset Password</button>

                        {this.state.error &&
                        <div className="alert alert-danger mt-4" role="alert">
                          {this.state.error}
                        </div> }

                        {this.state.success &&
                        <div className="alert alert-success mt-4" role="success">
                          {this.state.success}
                        </div> }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </App>
      );
    }
  }
);

export default ResetPassword;