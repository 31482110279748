import React, { useState, useEffect, useRef, useCallback, memo, useContext } from 'react';
import {RouterLink} from 'mobx-state-router';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

import App from './App';

const Account = observer(
  class Account extends React.Component {
    static contextType = RouterContext;
  
    constructor(props) {
      super(props);
      
      this.state = {
        name: '',
        email: '',
        password: '',
        emailOncePer: ''
      };
    }
    
    componentDidMount() {
      this.setState({
        name: this.context.options.userStore.data.name,
        email: this.context.options.userStore.data.email,
        emailOncePer: this.context.options.userStore.data.emailOncePer
      });
    }
  
    setName(name) {
      this.setState({name});
    }

    setEmail(email) {
      this.setState({email});
    }

    setPassword(password) {
      this.setState({password});
    }

    setEmailOncePer(emailOncePer) {
      this.setState({emailOncePer});
    }

    async update() {
      this.setState({error: null, success: null});
      const error = await this.context.options.userStore.update(this.state.name, this.state.email, this.state.password, this.state.emailOncePer);

      if (!error) {
        this.setState({success: 'Successfully updated account.'});
      } else {
        this.setState({error});
      }
    }

    async delete(evt) {
      if (evt) {
        evt.preventDefault();
      }
    
      if (confirm('Any workspaces you have will be automatically deleted.\nIf any workspaces have more than one member, those other members will become admin.\n\nAre you sure you want to delete your account?')) {
        await this.context.options.userStore.delete();
      }
    }

    render() {
      return (
        <App>
          <section className="fdb-block">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-7 col-md-5 text-center">
                  <div className="fdb-box fdb-touch">
                    <div className="row">
                      <div className="col">
                        <h1>My Account</h1>
                      </div>
                    </div>
        
                    <div className="row">
                      <div className="col mt-4">
                        <input type="text" className="form-control" onChange={evt => this.setEmail(evt.target.value)} value={this.state.email} placeholder="Your email" autoFocus={true} />
                      </div>
                    </div>
        
                    <div className="row mt-4">
                      <div className="col">
                        <input type="password" className="form-control" onChange={evt => this.setPassword(evt.target.value)} value={this.state.password} placeholder="Enter new password to change"/>
                      </div>
                    </div>
        
                    <div className="row mt-4">
                      <div className="col">
                        <input type="text" className="form-control mb-1" onChange={evt => this.setName(evt.target.value)} value={this.state.name} placeholder="Your (nick)name"/>
                      </div>
                    </div>
        
                    <div className="row mt-4">
                      <div className="col text-left">
                        <label htmlFor="email-once-per">Send e-mail notification when a workspace has changed:</label>

                        <select id="email-once-per" className="form-control" value={this.state.emailOncePer} onChange={evt => this.setEmailOncePer(evt.target.value)} onMouseDown={(evt) => {evt.stopPropagation()}} onMouseUp={(evt) => {evt.stopPropagation()}}>
                          <option value="never">Never</option>
                          <option value="5_min">Once per 5 minutes</option>
                          <option value="30_min">Once per 30 minutes</option>
                          <option value="1_hour">Once per hour</option>
                          <option value="1_day">Once per day</option>
                        </select>
                      </div>
                    </div>
        
                    <div className="row mt-4">
                      <div className="col">
                        <p className="text-right mt-2 small"><a href="#" onClick={(evt) => this.delete(evt)}>Delete My Account</a></p>
                      </div>
                    </div>
        
                    <div className="row mt-4">
                      <div className="col">
                        <button onClick={()=>this.update()} className="btn btn-dark btn-lg" type="button">Update Account</button>
        
                        {this.state.error &&
                        <div className="alert alert-danger mt-4" role="alert">
                          {this.state.error}
                        </div> }

                        {this.state.success &&
                        <div className="alert alert-success mt-4" role="success">
                          {this.state.success}
                        </div> }
        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>        
        </App>
      );
    }
  }
);

export default Account;