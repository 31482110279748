import React from 'react';
import { observer } from 'mobx-react';
import { RouterContext, RouterLink } from 'mobx-state-router';

import App from './App';

const CreateDesktop = observer(
  class CreateDesktop extends React.Component {
    static contextType = RouterContext;
    
    constructor(props) {
      super(props);
      
      this.state = {
        visibility: 'public'
      };
    }
    
    checkEnter(evt) {
      if (evt && evt.key == 'Enter') {
        this.create();
      }
    }
    
    render() {
      return (
        <App>
          <section id="create-desktop-form" className="fdb-block">
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-12 col-md-9 col-lg-8">
                  <div className="fdb-box fdb-touch">
        
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-10">
                        <h1>Create Workspace</h1>
        
                        { (false && !this.context.options.userStore.data.userUuid) && <>
                          <p className="lead smaller mb-1">
                            Public workspaces without user account are removed after 7 days.
                          </p>
                          <p className="lead smaller mb-1">
                            To make a workspace private, <RouterLink routeName="signup">Sign Up</RouterLink> or <RouterLink routeName="login">Log In</RouterLink> first.
                          </p>
                        </> }
                      </div>
                    </div>
        
                    <div className="row justify-content-center mt-4">
                      <div className="col col-12 col-lg-10">
                        <div className="input-group">
                          <input autoFocus={true} type="text" className="form-control" onChange={this.setTitle.bind(this)} onKeyDown={(evt) => this.checkEnter(evt)} placeholder="Choose title"/>
                        </div>
                      </div>
                    </div>
        
                    <div className="row justify-content-center mt-3">
                      <div className="col col-12 col-lg-10">
                        <div className="input-group">
                          <input type="text" onChange={this.setCustomUrl.bind(this)} onKeyDown={(evt) => this.checkEnter(evt)} className="form-control" placeholder="(Optional) Custom URL"/>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                      <div className="col col-12 col-lg-10">
                        <div className="input-group">
                          <button onClick={()=>this.create()} className="btn btn-dark btn-lg" type="button">Create Workspace Now</button>
                        </div>
                      </div>
                    </div>
        
                    { this.context.options.desktopStore.data.createError && this.context.options.desktopStore.data.createError.length > 0 &&
                    <div className="row justify-content-center mt-4">
                      <div className="col col-12 col-lg-10">
                        <div className="input-group">
                          <div className="alert alert-danger my-0" role="alert">
                            {this.context.options.desktopStore.data.createError}
                          </div>
                        </div>
                      </div>
                    </div> }
        
                    { false && !this.context.options.userStore.data.userUuid &&
                    <div className="row justify-content-center mt-1">
                      <div className="col col-12 col-lg-10">
                        <div className="input-group">
                          
                          <div className="mt-4">
                            <label className="form-check-label">
                              <p className="mb-1">
                                To make a workspace private, <RouterLink routeName="signup">Sign Up</RouterLink> or <RouterLink routeName="login">Log In</RouterLink> first.
                              </p>
                              <p>
                                Public workspaces without user account are removed after 7 days.
                              </p>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> }
        
                  </div>
                </div>
              </div>
            </div>
          </section>        
        </App>
      );
    }
    
    setTitle(event) {
      this.setState({title: event.target.value});
    }
    
    setCustomUrl(event) {
      this.setState({customUrl: event.target.value});
    }
    
    setVisibility() {
      this.setState({visibility: event.target.value});
    }
    
    create() {
      this.context.options.desktopStore.createDesktop(this.state);
    }
  }
);

export default CreateDesktop;