import React from 'react';
import {RouterLink} from 'mobx-state-router';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

import App from './App';

const Home = observer(
  class Home extends React.Component {
    static contextType = RouterContext;
      
    landingContent() {
      return (
        <App>
          <section className="head-block fdb-block">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-12 col-lg-6 col-xl-5">
                  <RouterLink routeName="createDesktop">
                    <h1>Effortless Collaboration<br/>For Teams</h1>
                  </RouterLink>
                  
                  <RouterLink routeName="createDesktop">
                    <p className="lead mb-3 mb-lg-5">Never lose your project files, folders and links. Share them with your team in one workspace, in real-time</p>
                  </RouterLink>
        
                  <p>
                    {false && <RouterLink routeName="createDesktop" className="btn btn-dark btn-lg mt-4">Create Workspace Now</RouterLink>}
                    <RouterLink routeName="createDesktop" className="btn btn-dark btn-lg mt-4">Create Workspace For Free</RouterLink>
                  </p>
                </div>
                <div className="col-12 col-md-8 mr-lg-0 col-lg-6 pt-5 pt-lg-0">
                  <video className="embed-responsive-item" loop playsInline muted autoPlay>
                    <source src={`${railsAssetsUrl}/birabo.mp4`} />
                  </video>
                </div>
              </div>
            </div>
          </section>
        
          <section className="fdb-block py-0">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-6 m-md-auto ml-lg-0 mr-lg-auto pt-5 pt-lg-0">
                  <img alt="image" className="img-fluid" src={`${railsAssetsUrl}/content/people-1.svg`}/>
                </div>
                <div className="col-12 col-lg-6 col-xl-5 pt-5 md-pt-0 text-center text-xl-left">
                  <div className="row pt-3">
                    <div className="col-12">
                      <h4><strong>Keep Files, Notes And Links Together</strong></h4>
                      <p>No more switching between e-mail, Dropbox and Google Docs.</p>
                    </div>
                    <div className="col-12 m-auto pt-3 pt-md-0">
                      <h4><strong>Collaborate In Real-Time</strong></h4>
                      <p>Share files and notes quickly, simply and safely.</p>
                    </div>
                    <div className="col-12 m-auto pt-3 pt-md-0">
                      <h4><strong>Stay Organized</strong></h4>
                      <p>Use tags, colors and assign members.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
                  
          <section className="fdb-block less-spacing">
            <div className="container">
              <div className="row text-center justify-content-center">
                <div className="col-lg-8">
                  <h2>You are in good company</h2>
                  <p className="lead">Stormly and dozens of other companies use Birabo</p>
                  <div className="mt-5 justify-content-center">
                    <a href="https://www.stormly.com" target="_blank"><img alt="image" height="60" className="ml-3 mr-3 mb-2 mt-2" src={`${railsAssetsUrl}/customers/stormly.svg`}/></a>
                    <a href="https://www.yogile.com" target="_blank"><img alt="image" height="76" className="ml-3 mr-3 mb-2 mt-2" src={`${railsAssetsUrl}/customers/yogile.svg`}/></a>
                    <a href="https://www.mentspot.com" target="_blank"><img alt="image" height="60" className="ml-3 mr-3 mb-2 mt-2" src={`${railsAssetsUrl}/customers/mentspot.svg`}/></a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        
          <section className="fdb-block less-spacing">
            <div id="testimonial-block" className="container py-5">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-9 col-xl-6">
                  <div className="fdb-box">
                    <div className="row">
                      { false && 
                      <div className="col-8 col-sm-6 col-md-4 col-xl-3 ml-auto mr-auto">
                        <img alt="image" className="img-fluid rounded" src={`${railsAssetsUrl}/people/aleks.jpg`}/>
                      </div> }
    
                      <div className="col-md-8 mt-4 mt-md-0">
                        <p className="lead">
                          "Birabo is such an easy and powerful tool, that finally makes it possible to quickly share resources when we're working on a specific project or new idea."
                        </p>
    
                        { false && <>
                          <p className="h3 mt-4 mt-xl-5"><strong>Aleks</strong></p>
                          <p><em>UX Designer at Stormly</em></p>
                        </> }
                      </div>
                    </div>
                  </div>
                </div>
    
                <div className="col-lg-9 col-xl-6 mt-4 mt-xl-0">
                  <div className="fdb-box">
                    <div className="row">
                      { false && 
                      <div className="col-8 col-sm-6 col-md-4 col-xl-3 ml-auto mr-auto">
                        <img alt="image" className="img-fluid rounded" src={`${railsAssetsUrl}/people/maurice.jpg`}/>
                      </div> }
    
                      <div className="col-md-8 mt-4 mt-md-0">
                        <p className="lead">
                          "Managing a team of remote developers has never been easier thanks to Birabo."
                        </p>
    
                        {false && <>
                          <p className="h3 mt-4 mt-xl-5"><strong>Maurice</strong></p>
                          <p><em>Product Manager at Yogile</em></p>
                        </> }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>        
            
          <section className="fdb-block less-spacing">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-7 text-center">
                  <h1>Collaborate without any effort</h1>
                  <p className="lead">
                    We've built a product that we needed for ourselves, and we'd like to share it with you.
                  </p>
                  <p className="mt-5 mt-sm-4">
                    <RouterLink routeName="createDesktop" className="btn btn-dark btn-lg">Create Workspace Now</RouterLink>
                  </p>
                </div>
              </div>
            </div>
          </section>
                          
        </App>
        );
    }
    
    render() {
      return this.landingContent();
    }
  }
);

export default Home;