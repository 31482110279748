// TODO add hot reload: https://github.com/pmmmwh/react-refresh-webpack-plugin
// Examples for router: https://nareshbhatia.github.io/mobx-state-router/docs/guides-recipes
  // Example app with auth. etc: https://github.com/nareshbhatia/mobx-state-router/blob/master/examples/mobx-shop/src

import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {browserHistory, createRouterState, HistoryAdapter, RouterStore, RouterContext, RouterView} from 'mobx-state-router';
import {Provider} from 'mobx-react';
import {decorate, observable} from 'mobx-react';

import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import {routes, viewMap} from '../config/routes';
import initRouter from '../config/initRouter';

const routerStore = initRouter();

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <RouterContext.Provider value={routerStore}>
      <RouterView viewMap={viewMap} />
    </RouterContext.Provider>,
    
    document.body.appendChild(document.createElement('div')),
  )
});

