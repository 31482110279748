import React from 'react';
import { observer } from 'mobx-react';
import { RouterContext } from 'mobx-state-router';

class DesktopSettingsModal extends React.Component {
  static contextType = RouterContext;

  constructor(props) {
    super(props);
  
    this.state = {
      title: props.desktopStore.data.title,
      customUrl: props.desktopStore.data.shortcode,
      visibility: props.desktopStore.data.visibility
    };
  }

  setTitle(evt) {
    this.setState({title: evt.target.value});
  }

  setCustomUrl(evt) {
    this.setState({customUrl: evt.target.value});
  }

  setVisibility(evt) {
    this.setState({visibility: evt.target.value});
  }

  async delete(evt) {
    if (evt) {
      evt.preventDefault();
    }
    
    if (confirm('Are you sure you want to delete this workspace?')) {
      await this.context.options.desktopStore.deleteDesktop();
      this.context.options.desktopStore.rootStore.goTo('home');
    }
  }

  async save() {
    this.setState({error: null, success: null});
    
    const currentCustomUrl = this.context.options.desktopStore.data.shortcode;
    const error = await this.context.options.desktopStore.updateDesktop(this.state);
    const newCustomUrl = this.context.options.desktopStore.data.shortcode;
    
    if (!error) {
      if (currentCustomUrl != newCustomUrl) {
        this.props.onCustomUrlChange();
        this.context.options.desktopStore.rootStore.goTo('desktop', {params: {id: newCustomUrl}});
      } else {
        this.setState({success: 'Successfully updated settings.'});
      }
    } else {
      this.setState({error});
    }
  }

  render() {
    const desktopStore = this.context.options.desktopStore;
    const desktopData = desktopStore.data;
    
    return (
      <div className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Settings</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(evt) => this.props.onClose(evt)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">

                  <div className="row mt-1">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="title">Title:</label>
                        <input autoFocus={true} type="text" onChange={this.setTitle.bind(this)} value={this.state.title} className="form-control" id="title" placeholder="Enter a title"/>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="url">Custom URL:</label>
                        <input type="text" onChange={this.setCustomUrl.bind(this)} value={this.state.customUrl} className="form-control" id="url" placeholder="Enter a custom URL"/>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="visibility">Privacy mode:</label>
          
                        <select id="visibility" className="form-control" value={this.state.visibility} onChange={this.setVisibility.bind(this)} onMouseDown={(evt) => {evt.stopPropagation()}} onMouseUp={(evt) => {evt.stopPropagation()}}>
                          <option value="public">Public - Everyone with link can read & write</option>

                          { this.context.options.userStore.data.userUuid &&
                          <option value="private">Private - Only invited people, with read/write control</option> }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col text-right">
                      <a href="#" onClick={(evt) => this.delete(evt)}>Delete Workspace</a><br/>
                    </div>
                  </div>

              </div>
            </div>

            <div className="container">
              <div className="col">
                {this.state.error &&
                <div className="alert alert-danger" role="alert">
                  {this.state.error}
                </div> }

                {this.state.success &&
                <div className="alert alert-success" role="success">
                  {this.state.success}
                </div> }
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={(evt) => this.props.onClose(evt)}>Cancel</button>
              { desktopStore.admin &&
              <button type="button" className="btn btn-primary" onClick={(evt) => this.save()}>Save</button> }
            </div>
          </div>
        </div>
      </div>        
    );
  }
}

export default DesktopSettingsModal;