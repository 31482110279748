import React, { useState, useEffect, useRef, useCallback, memo, useContext } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import {RouterLink} from 'mobx-state-router';
import { RouterContext } from 'mobx-state-router';

import { useItemFinishListener, useBatchAddListener } from "@rpldy/uploady";
import retryEnhancer, { useRetry } from '@rpldy/retry-hooks';
import UploadButton from '@rpldy/upload-button';
import UploadPreview from '@rpldy/upload-preview';
import Uploady, {useItemProgressListener, useItemAbortListener, useItemErrorListener, useAbortItem, UploadyContext, useRequestPreSend} from '@rpldy/uploady';
import { Circle } from 'rc-progress';

import 'array-flat-polyfill';

///// Upload related:

const STATES = {
    PROGRESS: 'PROGRESS',
    DONE: 'DONE',
    ABORTED: 'ABORTED',
    ERROR: 'ERROR'
};

const STATE_COLORS = {
    [STATES.PROGRESS]: '#f4e4a4',
    [STATES.DONE]: '#a5f7b3',
    [STATES.ABORTED]: '#f7cdcd',
    [STATES.ERROR]: '#ee4c4c'
};

const StyledCircle = styled(Circle)`
  width: 25px;
  height: 25px;
`;

const PreviewsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #0c86c1;
  margin-top: 10px;
`;

const PreviewImageWrapper = styled.div`
  height: 150px;
  text-align: center;
  width: 100%;
`;

const PreviewImage = styled.img`
  max-width: 200px;
  height: auto;
  max-height: 140px;
`;

const PreviewItemContainer = styled.div`
  width: 220px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ state }) => (state ? STATE_COLORS[state] : '#c3d2dd')} 0px
    8px 5px -2px;
  position: relative;
  align-items: center;
  margin: 0 10px 10px 0;
`;

const ImageName = styled.span`
  position: absolute;
  top: 10px;
  font-size: 12px;
  padding: 3px;
  background-color: #25455bab;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
`;

const PreviewItemBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
  box-shadow: #5dbdec 0px -3px 2px -2px;
`;

const ItemButtons = styled.div`
  button {
    width: 52px;
    height: 34px;
    font-size: 26px;
    line-height: 26px;
    cursor: pointer;
    margin-right: 4px;

    :disabled {
      cursor: not-allowed;
      background-color: grey;
      color: grey;
    }
  }
`;

const AbortButton = ({ id, state }) => {
    const abortItem = useAbortItem();
    const onAbort = useCallback(() => abortItem(id), [id, abortItem]);

    return (
      <span className={(state === STATES.ABORTED || state === STATES.DONE) ? 'abort disabled' : 'abort'} onClick={onAbort}>
        <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#stop-circle'}/></svg>
      </span>
    );
};

const RetryButton = ({ id, state }) => {
    const retry = useRetry();
    const onRetry = useCallback(() => retry(id), [id, retry]);

    return (
      <span className={state !== STATES.ABORTED ? 'retry disabled' : 'retry'} onClick={onRetry}>
        <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#refresh-cw'}/></svg>
      </span>
    );
};

// const QueueItem = memo((props) => {
const QueueItem = (props) => {
    const [progress, setProgress] = useState(0);
    const [itemState, setItemState] = useState(0);

    useItemProgressListener(item => {
      if (item.completed > progress) {
        setProgress(() => item.completed);
        setItemState(() =>
          item.completed === 100 ? STATES.DONE : STATES.PROGRESS
        );
      }
    }, props.id);

    useItemAbortListener((item) => {
      setItemState(STATES.ABORTED);
    }, props.id);

    useItemErrorListener((item) =>{
      setItemState(STATES.ERROR);
    }, props.id);

    if (itemState == STATES.DONE) {
      // return null;
    }

    return (
        <PreviewItemContainer state={itemState} className="upload-container">
            <PreviewItemBar className="upload-item-bar">
              {itemState == STATES.DONE && 
                <span className="finished">
                  <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#check-circle'}/></svg>
                </span>
              }
      
              <span className="upload-item-name">{props.name}</span>
              
              <ItemButtons>
                  <AbortButton id={props.id} state={itemState} className="upload-item-abort" />
                  <RetryButton id={props.id} state={itemState} className="upload-item-retry" />
              </ItemButtons>
                  
              {(itemState == STATES.PROGRESS && itemState != STATES.DONE) ?
              <StyledCircle
                  className="upload-item-progress"
                  strokeWidth={4}
                  percent={progress}
                  strokeColor={progress === 100 ? '#00a626' : '#2db7f5'}
                  /> : <StyledCircle className="upload-item-progress" strokeWidth={0} percent={0}/> }
            </PreviewItemBar>
        </PreviewItemContainer>
    );
};
// });

/////

const getPreviewProps = (item) => {
  return {id: item.id};
};

const checkFiles = (desktopStore, currentFile, currentFileIdx, batchFiles) => {
  if (currentFile.size > desktopStore.data.maxFileUploadBytes) {
    alert(`${currentFile.name} is larger than max. allowed upload of ${parseInt(desktopStore.data.maxFileUploadBytes/1024/1024)}MB`);
    return false;
  }
  
  return true;
};

const UploadHandler = (props) => {
  const uploadyContext = useContext(UploadyContext);
  
  useBatchAddListener((batch) => {
    props.desktopStore.setShowUploaderPreview(true);
  });
  
  useItemFinishListener((file) => {
    props.desktopStore.addItem(null, {title: file.file.name, color: 'blue', itemUuid: props.desktopStore.getItemUuidForUploadId(file.id), metadata: JSON.stringify({size: file.file.size, type: file.file.type, name: file.file.name, lastModified: file.file.lastModified})}, 'item', 'file');
  });

  useRequestPreSend(async ({items, options}) => {
    const signedUpload = await props.desktopStore.getSignedUploadUrl({uploadId: items[0].id, desktopUuid: props.desktopStore.data.uuid, filename: options.filename, parentUuid: options.parentUuid});

    const uploadOptions = {
      options: {
        params: signedUpload.fields,
        sendWithFormData: true,
        inputFieldName: 'file',
        concurrent: true,
        maxConcurrent: 4,
        method: 'POST',
        destination: {
          url: signedUpload.url
        }
      }
    };

    return uploadOptions;
  });

  return null;
};

const UploadComponent = observer((props) => {
  return (
    <Uploady enhancer={retryEnhancer} fileFilter={checkFiles.bind(this, props.desktopStore)}>
      <UploadHandler desktopStore={props.desktopStore} />

      <PreviewsContainer className={props.desktopStore.showUploaderPreview ? 'show' : 'hidden'}>
        <div className="close-uploader" onClick={() => { props.desktopStore.setShowUploaderPreview(false); }}>
          <span className="btn btn-outline-dark btn-sm">Hide</span>
        </div>        
  
        <UploadPreview previewMethodsRef={props.uploadPreviewRef} previewComponentProps={getPreviewProps} rememberPreviousBatches PreviewComponent={QueueItem} />
      </PreviewsContainer>
    
      {props.children}
    </Uploady>
  );
});

const App = observer(
  class App extends React.Component {
    static contextType = RouterContext;
    
    changeDesktop(evt) {
      const desktop = this.context.options.desktopStore.desktops.find(findDesktop => findDesktop.uuid == evt.target.value);
      
      if (desktop) {
        this.context.options.desktopStore.rootStore.goTo('desktop', {params: {id: desktop.shortcode}});
      }
    }

    render() {
      const desktopStore = this.context.options.desktopStore;
      const desktopData = desktopStore.data;
      const inDesktop = this.context.routerState.routeName == 'desktop' || this.context.routerState.routeName == 'desktopWithParent';
      const memberOfDesktop = desktopStore.desktops.find(findDesktop => findDesktop.uuid == desktopData.uuid);
      
      const menu = (
        <header id="navigator">
          <div className="container px-0 pb-1">
            <nav className="navbar navbar-expand-md no-gutters">
              <div className="logo-container col-2 text-left">
                <RouterLink routeName="home" className="nav-link" activeClassName="active">
                  <img src={`${railsAssetsUrl}/logo.svg`} height="26" alt="Birabo Logo"/>
                </RouterLink>
              </div>
    
              <button id="navbar-toggle-1" className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse-2" aria-controls="navbarNav7" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
        
              {!inDesktop &&
              <RouterLink routeName="home" className="nav-link d-lg-none" activeClassName="active">
                <img src={`${railsAssetsUrl}/logo.svg`} height="26" alt="Birabo Logo"/>
              </RouterLink>}
        
              {inDesktop && this.context.options.userStore.data.userUuid && desktopStore.desktops.length > 0 && 
              <select id="change-desktop" className="form-control" value={memberOfDesktop ? desktopData.uuid : 'external'} onChange={this.changeDesktop.bind(this)} onMouseDown={(evt) => {evt.stopPropagation()}} onMouseUp={(evt) => {evt.stopPropagation()}}>
                {!memberOfDesktop && 
                  <option value="external">External: {desktopData.title}</option>
                }

                {desktopStore.desktops.map(desktopItem => 
                  <option key={desktopItem.uuid} value={desktopItem.uuid}>{desktopItem.title}</option>
                )}
              </select>}
    
              {!this.context.options.userStore.data.userUuid && inDesktop && desktopData.uuid && 
              <select id="change-desktop" className="form-control" onMouseDown={(evt) => {evt.stopPropagation()}} onMouseUp={(evt) => {evt.stopPropagation()}}>
                <option value="">{desktopData.title}</option>
              </select>}
    
              {inDesktop &&
              <button id="navbar-toggle-2" className="navbar-toggler actions btn btn-dark" type="button" data-toggle="collapse" data-target=".navbar-collapse-3" aria-controls="navbarNav8" aria-expanded="false" aria-label="Toggle edit actions">
                <svg className="feather"><use xlinkHref={railsFeatherAssetsUrl + '#edit-3'}/></svg>
              </button>}
    
              <div className={"collapse navbar-collapse navbar-collapse-2 justify-content-end " + (this.context.options.userStore.data.userUuid ? "col-md-4" : "col-md-5")} id="navbarNav7">
                <ul className="navbar-nav justify-content-center">
                  <li className="nav-item d-md-none">
                    <RouterLink routeName="home" className="nav-link" activeClassName="active">Home</RouterLink>
                  </li>
                  <li className="nav-item">
                    <RouterLink routeName="createDesktop" className="nav-link" activeClassName="active">Create Workspace For Free</RouterLink>
                  </li>
                  {false && <li className="nav-item">
                    <RouterLink routeName="pricing" className="nav-link" activeClassName="active">Pricing</RouterLink>
                  </li>}
                </ul>
              </div>

              {this.context.options.userStore.data.userUuid && 
              <div className="collapse navbar-collapse navbar-collapse-2 col-md-3">
                <ul className="navbar-nav ml-auto justify-content-end">
                  <li className="nav-item">
                    <RouterLink routeName="account" className="nav-link" activeClassName="active">My Account</RouterLink>
                  </li>
                  <li className="nav-item">
                    <RouterLink routeName="logout" className="nav-link">Log&nbsp;Out</RouterLink>
                  </li>
                </ul>
              </div> }
              
              {!this.context.options.userStore.data.userUuid && 
              <div className="collapse navbar-collapse navbar-collapse-2">
                <ul className="navbar-nav ml-auto justify-content-end">
                  <li className="nav-item">
                    <RouterLink routeName="login" className="nav-link3">Log&nbsp;In</RouterLink>
                  </li>
                </ul>
    
                <RouterLink routeName="signup" className="btn signup btn-dark ml-md-3">Sign&nbsp;Up</RouterLink>
              </div> }
            </nav>
          </div>
        </header>
      );

      const footer = (
        <footer className="fdb-block footer-large bg-dark">
          <div className="container">
            <div className="row align-items-top text-center">
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-sm-left">
                <h3><strong>Links</strong></h3>
                <nav className="nav flex-column">
                  <RouterLink routeName="home" className="nav-link" activeClassName="active">Home</RouterLink>
                  <RouterLink routeName="contact" className="nav-link" activeClassName="terms">Contact Us</RouterLink>
                  <a href="/terms" target="_blank" className="nav-link">Terms of Use</a>
                  <a href="/privacy" target="_blank" className="nav-link">Privacy Policy</a>
                  <RouterLink routeName="pricing" className="nav-link" activeClassName="pricing">Pricing</RouterLink>
                </nav>
              </div>
              
              {false && 
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-5 mt-sm-0 text-sm-left">
                <h3><strong>Group 2</strong></h3>
                <nav className="nav flex-column">
                  <a className="nav-link active" href="#">Privacy Policy</a>
                  <a className="nav-link" href="#">Terms</a>
                  <a className="nav-link" href="#">FAQ</a>
                  <a className="nav-link" href="#">Support</a>
                </nav>
              </div>} 
    
              <div className="col-12 col-md-4 col-lg-3 text-md-left mt-5 mt-md-0">
                <h3><strong>About Us</strong></h3>
                <p>A Simple Tool For Effortless Collaboration.</p>
              </div>
    
              {false && 
              <div className="col-12 col-lg-2 ml-auto text-lg-left mt-4 mt-lg-0">
                <h3><strong>Follow Us</strong></h3>
                <p className="lead">
                  <a href="#" className="mx-2"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                  <a href="#" className="mx-2"><i className="fab fa-facebook" aria-hidden="true"></i></a>
                  <a href="#" className="mx-2"><i className="fab fa-instagram" aria-hidden="true"></i></a>
                  <a href="#" className="mx-2"><i className="fab fa-pinterest" aria-hidden="true"></i></a>
                  <a href="#" className="mx-2"><i className="fab fa-google" aria-hidden="true"></i></a>
                </p>
              </div>}
            </div>
    
            <div className="row mt-3">
              <div className="col text-center">
                © {new Date().getFullYear()} Birabo.com. All Rights Reserved
              </div>
            </div>
          </div>
        </footer>        
      );

      if (this.props.fullSize) {
        return (
          <>
            <UploadComponent desktopStore={desktopStore} uploadPreviewRef={desktopStore.uploadPreviewRef}>
              {menu}
          
              <div>
                {this.props.children}
              </div>
            </UploadComponent>
          </>
        );
      } else {
        return (
          <div>
            {menu}
            
            <div>
              {this.props.children}
            </div>
                
            {footer}
          </div>
        );
      }
    }
  }
);

export default App;